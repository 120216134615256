import React, { Fragment, useState, useEffect } from "react"
import { getPresignedUrlForGet, 
  displayLoader, 
  hideLoader, 
  dynamicSort, 
  parseDate, 
  cartasCompromiso, 
  setHistorialData } from "../utils.js"
import useDynamicRefs from 'use-dynamic-refs'
import curso from "../assets/img/curso.svg"
import documentacion from "../assets/img/documentacion.svg"
import entrevista from "../assets/img/entrevista.svg"
import practica from "../assets/img/practica.svg"
import prueba from "../assets/img/prueba.svg"
import '../styles/applicant.scss'
import '../styles/expediente.scss'

const Expediente = ({ token, applicant, downloadFile, historial }) => {
  const [pasosData, setPasosData] = useState([])
  const [startProcessDate, setStartProcessDate] = useState('')
  const [jefeZona, setJefeZona] = useState({})
  const [jefeComercial, setJefeComercial] = useState({})
  const [gerente, setGerente] = useState({})

  useEffect(() => {
    if(historial){
      setHistorialData(historial, setStartProcessDate, null, setPasosData)
    }
  }, [historial]);

  function downloadCartasCompromiso(){
    cartasCompromiso.forEach(function(carta){
      downloadFile(`${applicant.id}-Memo-${carta}.pdf`)
    })
  }

  useEffect(() => {
    if(applicant && applicant.jefeZona){
      const url = process.env.REACT_APP_BASE_URL + '/workflow/users'

      fetch(url, {
        method: "GET",
        headers: {
          token: token,
          "Content-Type": "application/json"
        }
      })
      .then(res => res.json())
      .then(
        (result) => {
          const jz = result.Items.find(j => j.id === applicant.jefeZona.id)
          const jc = result.Items.find(j => j.id === jz.id_jefe_comercial)
          const g = result.Items.find(j => j.id === jz.id_gerente)
          setJefeZona(jz)
          setJefeComercial(jc)
          setGerente(g)
        },
        (error) => {
          alert(error)
        }
      )
    }
  }, [applicant]);

  function getTiempoEnPaso(step){
    const paso = pasosData.find(p => p.step === step)
    if(paso){
      const dias = Math.round((paso.unparsedEndDate - paso.unparsedStartDate)/(1000*60*60*24))
      return dias.toString() + ' días'
    }
    return ''
  }

  return (
    <div className='expediente'>
      <h1>Expediente de { applicant.name } { applicant.lastName }</h1>
      <h2>Postulación iniciada el {parseDate(applicant.startDate)}</h2>

      <div className="section">
        <div className="section-title"><span>DETALLE</span></div>
      </div>

      {
        applicant.FechaPsicologico &&
        <div className="step-info">
          <img className="expediente-icon" src={entrevista} />
          <div className="expediente-date">{ applicant.FechaPsicologico }</div>
          <div className="expediente-content">
            <div className="expediente-content-title"><b>Entrevista | </b>Psicológica</div>
            { applicant.InformePsicologico.map((informe, key) => {
                return(
                  <button 
                    className='download-file-button-expediente' 
                    onClick={() => downloadFile(informe)}>
                    Ver Archivo {key + 1}
                  </button>
                )
              })
            }
            <div className="expediente-content-info"><b>Nota: </b>{ applicant.NotaPsicologico }</div>
            <div className="expediente-content-info"><b>Recomendaciones: </b>{ applicant.RecomendacionPsicologico }</div>
          </div>
        </div>
      }
      {
        applicant.FechaJefeZona &&
        <div className="step-info">
          <img className="expediente-icon" src={entrevista} />
          <div className="expediente-date">{ applicant.FechaJefeZona }</div>
          <div className="expediente-content">
            <div className="expediente-content-title"><b>Entrevista Jefe de zona | </b>{ jefeZona.name }</div>
            <div className="expediente-content-info"><b>Nota: </b>{ applicant.NotaJefeZona }</div>
            <div className="expediente-content-info"><b>Comentarios: </b>{ applicant.ComentarioJefeZona }</div>
          </div>
        </div>
      }
      {
        applicant.FechaJefeComercial &&
        <div className="step-info">
          <img className="expediente-icon" src={entrevista} />
          <div className="expediente-date">{ applicant.FechaJefeComercial }</div>
          <div className="expediente-content">
            <div className="expediente-content-title"><b>Entrevista Jefe comercial | </b>{ jefeComercial.name }</div>
            <div className="expediente-content-info"><b>Nota: </b>{ applicant.NotaJefeComercial }</div>
            <div className="expediente-content-info"><b>Comentarios: </b>{ applicant.ComentarioJefeComercial }</div>
          </div>
        </div>
      }
      {
        applicant.FechaGerenteOficina &&
        <div className="step-info">
          <img className="expediente-icon" src={entrevista} />
          <div className="expediente-date">{ applicant.FechaGerenteOficina }</div>
          <div className="expediente-content">
            <div className="expediente-content-title"><b>Entrevista Gerente Oficina | </b>{ gerente.name }</div>
            <div className="expediente-content-info"><b>Nota: </b>{ applicant.NotaGerenteOficina }</div>
            <div className="expediente-content-info"><b>Comentarios: </b>{ applicant.ComentarioGerenteOficina }</div>
          </div>
        </div>
      }
      {
        applicant.FechaCursoInduccion &&
        <div className="step-info">
          <img className="expediente-icon" src={curso} />
          <div className="expediente-date">{ applicant.FechaCursoInduccion }</div>
          <div className="expediente-content">
            <div className="expediente-content-title"><b>Curso | </b>Inducción</div>
            <div className="expediente-content-info"><b>Nota: </b>{ applicant.NotaCursoInduccion }</div>
            <button className='download-file-button-expediente' onClick={() => downloadFile(applicant.InformeCursoInduccion)}>Ver Informe</button>
            <button className='download-file-button-expediente' onClick={() => downloadFile(applicant.CartaRecomendacionCursoInduccion)}>Ver Carta de recomendación</button>
          </div>
        </div>
      }
      {
        applicant.FechaPractica  &&
        <div className="step-info">
          <img className="expediente-icon" src={curso} />
          <div className="expediente-date">{ applicant.FechaPractica }</div>
          <div className="expediente-content">
            <div className="expediente-content-title"><b>Práctica | </b>EDS</div>
            <div className="expediente-content-info"><b>Nota: </b>{ applicant.NotaPractica }</div>
            <div className="expediente-content-info"><b>Tutor: </b>{ applicant.TutorPractica }</div>
            <div className="expediente-content-info"><b>EDS: </b>{ applicant.EdsPractica }</div>
            <button className='download-file-button-expediente' onClick={() => downloadFile(applicant.InformePractica)}>Ver Informe</button>
          </div>
        </div>
      }
      {
        applicant.FechaPrueba  &&
        <div className="step-info">
          <img className="expediente-icon" src={prueba} />
          <div className="expediente-date">{ applicant.FechaPrueba }</div>
          <div className="expediente-content">
            <div className="expediente-content-title"><b>Prueba | </b>Certificación</div>
            <div className="expediente-content-info"><b>Nota: </b>{ applicant.NotaPrueba }</div>
            <button className='download-file-button-expediente' onClick={() => downloadFile(applicant.CertificadoPrueba)}>Ver Certificado</button>
          </div>
        </div>
      }
      {
        <div className="step-info">
          <img className="expediente-icon" src={documentacion} />
          { applicant.endDate !== 0 &&
            <div className="expediente-date">{ parseDate(applicant.endDate) }</div>
          }
          <div className="expediente-content">
            <div className="expediente-content-title"><b>Documentación | </b>Cierre del proceso</div>
            { applicant.MemoFinanzas &&
              <button className='download-file-button-expediente' onClick={() => downloadFile(applicant.MemoFinanzas)}>
                Memo Gcia. Adm y finanzas
              </button>
            }
            { applicant.DocFinanzas &&
              <button className='download-file-button-expediente' onClick={() => downloadFile(applicant.DocFinanzas)}>
                Informe comercial
              </button>
            }
            { applicant.MemoSociedad &&
              <button className='download-file-button-expediente' onClick={() => downloadFile(applicant.MemoSociedad)}>
                Memo Subg. Legal | Sociedad
              </button>
            }
            { applicant.DocSociedad &&
              <button className='download-file-button-expediente' onClick={() => downloadFile(applicant.DocSociedad)}>
                Constitución sociedad aprobada por As. Legal
              </button>
            }
            { applicant.MemoHipoteca &&
              <button className='download-file-button-expediente' onClick={() => downloadFile(applicant.MemoHipoteca)}>
                Memo Subg. Legal | Hipoteca
              </button>
            }
            { applicant.DocHipoteca &&
              <button className='download-file-button-expediente' onClick={() => downloadFile(applicant.DocHipoteca)}>
                Constitución hipoteca
              </button>
            }
            { applicant.step > process.env.REACT_APP_CARTAS_COMPROMISO_STEP &&
              <button className='download-file-button-expediente' onClick={downloadCartasCompromiso}>
                Cartas de Compromiso
              </button>
            }
            { applicant.step > process.env.REACT_APP_CARTON_SIGN_FIRST_STEP &&
              <button className='download-file-button-expediente' onClick={() => downloadFile(`${applicant.id}-memo-carton-nombramiento`)}>
                Cartón de Nombramiento
              </button>
            }
          </div>
        </div>
      }
    </div>
  )
};

export default Expediente;
