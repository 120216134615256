import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker"
import { Modal } from "react-responsive-modal"
import UsersTable from "./UsersTable"

import { 
  displayLoader,
  hideLoader,
  showGreyBackground,
  hideGreyBackground,  
  parseDate,
  parentRedirect,
  customStylesSelect,
  SingleOption,
  orderArray,
  scrollTo
} from "../utils.js";

import { etapasDictionary } from '../dictionary.js'
import Select from 'react-select'
import '../styles/users.scss'

const Users = ({token}) => {
  const [jefesZonaUsers, setJefesZonaUsers] = useState([])
  const [webdoxUsers, setWebdoxUsers] = useState([])
  const [gerenteEdsUsers, setGerenteEdsUsers] = useState([])
  const [gestoresUsers, setGestoresUsers] = useState([])
  const [gerentesUsers, setGerentesUsers] = useState([])
  const [jefeComercialUsers, setJefeComercialUsers] = useState([])
  const [gerenteComercialUsers, setGerenteComercialUsers] = useState([])
  const [jefeProductoSeleccionUsers, setJefeProductoSeleccionUsers] = useState([]) 
  const [jefeProductoFormacionUsers, setJefeProductoFormacionUsers] = useState([]) 
  const [selectedUser, setSelectedUser] = useState()
  const [selectedUserName, setSelectedUserName] = useState()

  useEffect(() => {
    if(token)
      getUsers()
  }, [token]);

  function getUsers(){
    const url = process.env.REACT_APP_BASE_URL + '/workflow/users'
    fetch(url,
      { headers: { token: token }})
      .then(res => res.json())
      .then((result) => {
        classifyUsers(result.Items)
      },
      (error) => {
        alert(error);
      }
    )
  }

  async function saveUser(){
    const data = {
      id: selectedUser.id,
      name: selectedUser.name,
      rut: selectedUser.rut,
      email: selectedUser.email
    }

    const url = process.env.REACT_APP_BASE_URL + '/workflow/users'
    
    await fetch(url,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'token': token
      },
      body: JSON.stringify(data)
    })
    .then((result) => {
      getUsers()
      setSelectedUserName()
      setSelectedUser()
      hideGreyBackground()
      },
      (error) => {
        alert(error);
      }
    )
  }

  function classifyUsers(users){
    const jefeszona = []
    const webdox = []
    const gerenteEds = []
    const gestores = []
    const gerentes = []
    const jefeComercial = []
    const gerenteComercial = []
    const jefeProductoSeleccion = []
    const jefeProductoFormacion = []

    users.forEach((user) => {
      if(user.type === 'JefeZona')
        jefeszona.push(user)
      else if(user.type === 'Webdox')
        webdox.push(user)
      else if(user.type === 'GerenteEDS')
        gerenteEds.push(user)
      else if(user.type === 'Gestor')
        gestores.push(user)
      else if(user.type === 'Gerente')
        gerentes.push(user)
      else if(user.type === 'JefeComercial')
        jefeComercial.push(user)
      else if(user.type === 'GerenteComercial')
        gerenteComercial.push(user)
      else if(user.type === 'JefeProductoSeleccion')
        jefeProductoSeleccion.push(user)
      else if(user.type === 'JefeProductoFormacion')
        jefeProductoFormacion.push(user)
    })

    setJefesZonaUsers(jefeszona)
    setWebdoxUsers(webdox)
    setGerenteEdsUsers(gerenteEds)
    setGestoresUsers(gestores)
    setGerentesUsers(gerentes)
    setJefeComercialUsers(jefeComercial)
    setGerenteComercialUsers(gerenteComercial)
    setJefeProductoSeleccionUsers(jefeProductoSeleccion)
    setJefeProductoFormacionUsers(jefeProductoFormacion)
  }

  function editUser(user){
    setSelectedUser(user)
    setSelectedUserName(user.name)
    scrollTo(0, 100)
    showGreyBackground()
  }

  function closeModal(){
    setSelectedUser()
    hideGreyBackground()
  }

  return (
    <div className="report">
      <div className="top-info">
        <div className="view-title"><b>Postulación concesionarios | </b>Administración de Usuarios</div>
        <hr className="title-hr" />
      </div>

      <div className="usuarios-section-title">Gerente Comercial</div>
      <UsersTable
        users={gerenteComercialUsers}
        getUsers={getUsers}
        token={token}
      />

      <div className="usuarios-section-title">Gerente EDS</div>
      <UsersTable
        users={gerenteEdsUsers}
        getUsers={getUsers}
        token={token}
      />

      <div className="usuarios-section-title">Jefe Producto Selección</div>
      <UsersTable
        users={jefeProductoSeleccionUsers}
        getUsers={getUsers}
        token={token}
      />

      <div className="usuarios-section-title">Jefe Formación Concesionarios</div>
      <UsersTable
        users={jefeProductoFormacionUsers}
        getUsers={getUsers}
        token={token}
      />

      <div className="usuarios-section-title">Gerentes</div>
      <UsersTable
        users={gerentesUsers}
        getUsers={getUsers}
        token={token}
        showZona={true}
      />

      <div className="usuarios-section-title">jefes Comerciales</div>
      <UsersTable
        users={jefeComercialUsers}
        getUsers={getUsers}
        token={token}
        showZona={true}
      />

      <div className="usuarios-section-title">Gestores Postulación</div>
      <UsersTable
        users={gestoresUsers}
        getUsers={getUsers}
        token={token}
        showZona={true}
      />

      <div className="usuarios-section-title">Webdox</div>
      <UsersTable
        users={webdoxUsers}
        getUsers={getUsers}
        token={token}
        showZona={true}
      />

      <div className="usuarios-section-title">Jefes de Zona</div>
      <UsersTable
        users={jefesZonaUsers}
        getUsers={getUsers}
        token={token}
        showSede={true}
        showZona={true}
      />
    </div>
  )
};

export default Users;