import React, { useState } from "react"
import ReactExport from "react-export-excel"
import descargar from "../assets/img/descargar.svg"
import { pasos } from "../utils.js"

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const ExportExcel = ({ data }) => {
  const labels = [
    ["Postulante", "fullName"]
  ];

  pasos.map(function(paso){
    const step = paso.step.toString()
    const labelName = "Paso " + step + ": " + paso.label
    const paramName = 'timeInStep' + step
    labels.push([labelName, paramName])
  })

  labels.push(    
    ["Fecha Inicio", "startDate"],
    ["Fecha Término", "endDate"],
    ["Tiempo Total", "totalTime"],
    ["Proceso Terminado", "isFinished"]
  )

  return (
    <ExcelFile element={<a><img src={descargar} className="cursor-pointer" /></a>} filename="Datos Exportados">
      <ExcelSheet data={data} name="Hoja 1">
        {
          labels.map((text,key)=><ExcelColumn key={key} label={text[0]} value={text[1]} />)
        }
      </ExcelSheet>
    </ExcelFile>
  );
};
export default ExportExcel