import React, { useState, useEffect } from "react";
import '../styles/requirements.scss';

const Requirements = () => {
  return (
    <div className='requirements'>
      <div className='columns'>
        <div className='col-left'>
          <div className='title'>
            Postulación nuevos concesionarios
          </div>
          <div className='subtitle'>
            Te invitamos a ser parte de nuestra red de Concesionarios a lo largo de todo Chile.
          </div>
        </div>
        <div className='col-right'>
          <div className='title'>Para postular deberá cumplir con los siguientes requisitos:</div>
          <div className='body'>
            Deberá tener un borrador (para revisión de Copec) de una escritura de constitución  de una sociedad de responsabilidad limitada para la administración de la Estación de Servicio, en que el Representante Legal debe tener a o menos el 51% de la sociedad
            <ul>
              <li>Representante Legal y socios, deben contar con antecedentes legales y Comerciales en orden.</li>
              <li>El representante legar y cada socio derán tener antecedentes morales, legales y comerciales intachables.</li>
              <li>El Representante Legal deberá tener preferentemente un título universitario o técnico profesional y tener una experiencia laboral mínima de 3 años.</li>
              <li>Deberá contar con una garantía hipotecaria para ser entregado a favor de Copec (primera hipoteca) de a lo menos 4.000 U.F. considerando el valor de liquidación. El valor estipulado puede variar de acuerdo a la Estación de Servicio que postule (Observable)</li>
              <li>Deberá tener un capital de trabajo, líquido, mínimo de 2.000 U.F. demostrable (Observable)</li>
              <li>Deberá tener dedicación exclusiva para operar los negocios que se postula.</li>
            </ul>
            <div className='note'>NOTA: Es importante señalar que si no cumple con alguno de estos requisitos no es posible postular.</div>
            <input type='checkbox' />He leido y acepto las condiciones indicadas

            <button className='apply-button'>Postular</button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Requirements;
