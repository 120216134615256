import React, { useState, useEffect } from "react"
import history from "../history"
import { 
  displayLoader,
  hideLoader,
  parentRedirect,
  getRemainingTime,
  showGreyBackground,
  hideGreyBackground,
  parseDate,
  customStylesSelect,
  SingleOption,
  orderArray
} from "../utils.js"
import { etapasDictionary } from '../dictionary.js'
import { Modal } from "react-responsive-modal"
import Select from 'react-select'
import 'react-responsive-modal/styles.css';
import '../styles/processes.scss'
import '../styles/modal.scss'

import Flecha_down from "../assets/img/caret_down.svg";
import Flecha_up from "../assets/img/caret_up.svg";

const etapas = [
  { value: 'all', label: 'Todas' },
  { value: 'Sel', label: 'Selección' },
  { value: 'Cap', label: 'Centro de evaluación' },
  { value: 'Nom', label: 'Nombramiento' },
  { value: 'Toma', label: 'Toma de la EDS' }
]

const zonas = [
  { value: 'all', label: 'Todas' },
  { value: 'norte', label: 'Norte' },
  { value: 'santiago', label: 'Santiago' },
  { value: 'centro', label: 'Centro' },
  { value: 'sur', label: 'Sur' }
]

const Processes = ({ token, user }) => {
  const [ processes, setProcesses ] = useState([])
  const [ filteredProcesses, setFilteredProcesses ] = useState([])
  const [ openModal, setOpenModal ] = useState(false)
  const [ selectedPostulation, setSelectedPostulation ] = useState({})
  const [ jefesZona, setJefesZona ] = useState([])
  const [ jefesComerciales, setJefesComerciales ] = useState([])
  const [ jefeComercial, setJefeComercial ] = useState({value: 'Todos', label: 'Todos'})
  const [ chosenJefeZona, setChosenJefeZona ] = useState()
  const [ etapaFilter, setEtapaFilter ] = useState(etapas[0])
  const [ zonaFilter, setZonaFilter ] = useState(zonas[0])
  const [ users, setUsers ] = useState([])
  const [ isJefeProductoSeleccion, setIsJefeProductoSeleccion ] = useState(false)

  useEffect(() => {
    if(token){
      getProcesses()
      getUsers()
    }
  }, [token]);

  useEffect(() => {
    if(user && user.metadata && users.length > 0){
      const jefeProdSel = users.find(u => u.type === 'JefeProductoSeleccion')
      if(user.metadata.email === jefeProdSel.email){
        setIsJefeProductoSeleccion(true)
      }

      const adminCopecGroup = 'GROUP#' + process.env.REACT_APP_ADMINISTRACION_COPEC_GROUP_ID
      if(user.groups.includes(adminCopecGroup)){
        setIsJefeProductoSeleccion(true)
      }
    }
  }, [user, users]);

  useEffect(() => {
    filter()
  }, [etapaFilter, zonaFilter, jefeComercial]);

  useEffect(() => {
    if(processes.length > 0 && users.length > 0)
      getJefesComerciales()

  }, [processes, users]);

  function getUsers(){
    const url = process.env.REACT_APP_BASE_URL + '/workflow/users'
    fetch(url,
      { headers: { token: token }})
      .then(res => res.json())
      .then((result) => {
        setUsers(result.Items)
      },
      (error) => {
        alert(error);
      }
    )
  }

  function filter(){
    let newFilteredProcesses = []
    processes.forEach(function(process){
      if((process.etapa === etapaFilter.value || etapaFilter.value === 'all') && 
        (process.zone === zonaFilter.value || zonaFilter.value === 'all') &&
        (jefeComercial.value === 'Todos' || (process.jefeZona && process.jefeZona.id_jefe_comercial === jefeComercial.value))
         ){
        newFilteredProcesses.push(process)
      }
    })
    
    setFilteredProcesses(newFilteredProcesses)
  }

  function getUserName(id){
    const tmpUser = users.find(u => u.id === id)
    if(tmpUser)
      return tmpUser.name
    
    return ''
  }

  function getProcesses(){
    const url = process.env.REACT_APP_BASE_URL + '/workflow/processes'

    fetch(url,
      { headers: { token: token }}
    ).then(res => res.json())
    .then((result) => {
      setProcesses(result)
      setFilteredProcesses(result)
      },
      (error) => {
        alert(error);
      }
    )
  }


  function assignJefeZona(postulation){
    setSelectedPostulation(postulation)
    setOpenModal(true)
    showGreyBackground()

    const url = process.env.REACT_APP_BASE_URL + '/workflow/users?zone=' + postulation.zone

    fetch(url,
      { headers: { token: token }}
    ).then(res => res.json())
    .then((result) => {
      const jz = []
      result.Items.forEach((item) => {
        if(item.type === 'JefeZona')
          jz.push(item) 
      })
      setJefesZona(jz)
      },
      (error) => {
        alert(error);
      }
    )
  }

  async function saveJefeZona(){
    if(chosenJefeZona){
      displayLoader()
      const postulation = {}

      postulation.id = selectedPostulation.id
      postulation.jefeZona = chosenJefeZona
      postulation.idJefeZona = chosenJefeZona.id

      await fetch(process.env.REACT_APP_BASE_URL + "/workflow/processes",
      {
        method: 'PUT',    
        headers: {
          'Content-Type': 'application/json',
          'token': token
        },
        body: JSON.stringify(postulation)
      })
      .then((result) => {
        setOpenModal(false)
        hideGreyBackground()
        setChosenJefeZona()
        setJefesZona([])
        getProcesses()
        },
        (error) => {
          alert(error);
        }
      )
      hideLoader()
    }
  }

  function getJefesComerciales(){
    const jefes = [{value: 'Todos', label: 'Todos'}]

    processes.forEach(function(pos){
      if(!pos.jefeZona)
        return

      const tmpJefe = users.find(u => u.id === pos.jefeZona.id_jefe_comercial)

      if(!jefes.find(e => e.value === tmpJefe.id))
        jefes.push({value: tmpJefe.id, label: tmpJefe.name})
    })
    setJefesComerciales(jefes)
  }

  const handleApplicantClick = (process) => {
    if(!(process.jefeZona && process.jefeZona.name) && process.step !== 1){
      alert("Se tiene que asignar un jefe de zona para poder continuar.")
      return
    }

    const url = "/c-postulante/" + process.id;
    parentRedirect(url)
  }

  const orderTable = (filterkey, order, parse) => {
    const arr = orderArray(filterkey, filteredProcesses, order, parse)
    setFilteredProcesses(arr)
  }

  return (
    <div className='processes'>
      <div className="top-info">
        <div className="view-title"><b>Postulación concesionarios | </b>Solicitudes</div>
        <hr className="title-hr" />
      </div>

      <div className="filters">
        <div className="filter-container">
          <div className="filter-title">ETAPA</div>
          <Select
            className='filter-select'
            value={etapaFilter}
            onChange={setEtapaFilter}
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
            options={etapas}
            components={{ SingleOption, IndicatorSeparator: () => null }}
            isClearable={false}
            isSearchable={false}
            autosize={false}
            placeholder="Seleccionar"
            styles={customStylesSelect}
          />
        </div>

        <div className="filter-container">
          <div className="filter-title">ZONA</div>
          <Select
            className='filter-select'
            value={zonaFilter}
            onChange={setZonaFilter}
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
            options={zonas}
            components={{ SingleOption, IndicatorSeparator: () => null }}
            isClearable={false}
            isSearchable={false}
            autosize={false}
            placeholder="Seleccionar"
            styles={customStylesSelect}
          />
        </div>

        <div className="filter-container">
          <div className="filter-title">JEFE COMERCIAL</div>
          <Select
            className='filter-select'
            value={jefeComercial}
            onChange={setJefeComercial}
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
            options={jefesComerciales}
            components={{ SingleOption, IndicatorSeparator: () => null }}
            isClearable={false}
            isSearchable={false}
            autosize={false}
            placeholder="Seleccionar"
            styles={customStylesSelect}
          />
        </div>
      </div>

      <table className="copec-table-base processes-table">
        <tr>
          <th className="first">
            <div className="th-data"><div className="th-text">RUT
              <div className="arrows">
                <img
                  className="arrow-up"
                  onClick={() => orderTable("rut", "asc")}
                  src={Flecha_up}
                />
                <img
                  className="arrow-down"
                  onClick={() => orderTable("rut", "desc")}
                  src={Flecha_down}
                />
              </div>
            </div></div>
          </th>
          <th>
            <div className="th-data"><div className="th-text">Nombre
              <div className="arrows">
                <img
                  className="arrow-up"
                  onClick={() => orderTable("lastName", "asc")}
                  src={Flecha_up}
                />
                <img
                  className="arrow-down"
                  onClick={() => orderTable("lastName", "desc")}
                  src={Flecha_down}
                />
              </div>
              </div></div>
          </th>
          <th>
            <div className="th-data"><div className="th-text">Email
              <div className="arrows">
                <img
                  className="arrow-up"
                  onClick={() => orderTable("email", "asc")}
                  src={Flecha_up}
                />
                <img
                  className="arrow-down"
                  onClick={() => orderTable("email", "desc")}
                  src={Flecha_down}
                />
              </div>
            </div></div>
          </th>
          <th>
            <div className="th-data"><div className="th-text">Fecha Inicio
              <div className="arrows">
                <img
                  className="arrow-up"
                  onClick={() => orderTable("startDate", "asc")}
                  src={Flecha_up}
                />
                <img
                  className="arrow-down"
                  onClick={() => orderTable("startDate", "desc")}
                  src={Flecha_down}
                />
              </div>
            </div></div>
          </th>
          <th>
            <div className="th-data"><div className="th-text">Zona
              <div className="arrows">
                <img
                  className="arrow-up"
                  onClick={() => orderTable("zone", "asc")}
                  src={Flecha_up}
                />
                <img
                  className="arrow-down"
                  onClick={() => orderTable("zone", "desc")}
                  src={Flecha_down}
                />
              </div>
            </div></div>
          </th>
          <th>
            <div className="th-data"><div className="th-text">Paso
              <div className="arrows">
                <img
                  className="arrow-up"
                  onClick={() => orderTable("step", "asc")}
                  src={Flecha_up}
                />
                <img
                  className="arrow-down"
                  onClick={() => orderTable("step", "desc")}
                  src={Flecha_down}
                />
              </div>
            </div></div>
          </th>
          <th>
            <div className="th-data"><div className="th-text">Etapa
              <div className="arrows">
                <img
                  className="arrow-up"
                  onClick={() => orderTable("etapa", "asc")}
                  src={Flecha_up}
                />
                <img
                  className="arrow-down"
                  onClick={() => orderTable("etapa", "desc")}
                  src={Flecha_down}
                />
              </div>
            </div></div>
          </th>
          <th>
            <div className="th-data"><div className="th-text">Jefe Comercial
              <div className="arrows">
                <img
                  className="arrow-up"
                  onClick={() => orderTable("jefe_comercial", "asc")}
                  src={Flecha_up}
                />
                <img
                  className="arrow-down"
                  onClick={() => orderTable("jefe_comercial", "desc")}
                  src={Flecha_down}
                />
              </div>
            </div></div>
          </th>
          <th className="last">
            <div className="th-data"><div className="th-text">Jefe de Zona
              <div className="arrows">
                <img
                  className="arrow-up"
                  onClick={() => orderTable("jefeZona", "asc")}
                  src={Flecha_up}
                />
                <img
                  className="arrow-down"
                  onClick={() => orderTable("jefeZona", "desc")}
                  src={Flecha_down}
                />
              </div>
            </div></div>
          </th>
        </tr>
        <tbody>
          {
            filteredProcesses.map((process) => {
              return(
                <tr key={process.rut}>
                  <td onClick={() => handleApplicantClick(process)}>
                    {process.rut}
                  </td>
                  <td onClick={() => handleApplicantClick(process)}>
                    {process.name} {process.lastName}
                  </td>
                  <td onClick={() => handleApplicantClick(process)}>
                    {process.email}
                  </td>
                  <td onClick={() => handleApplicantClick(process)}>
                    {parseDate(process.startDate)}
                  </td>
                  <td onClick={() => handleApplicantClick(process)}>
                    {process.zone}
                  </td>
                  <td onClick={() => handleApplicantClick(process)}>
                    {process.step}
                  </td>
                  <td onClick={() => handleApplicantClick(process)}>
                    {etapasDictionary[process.etapa]}
                  </td>
                  <td onClick={() => handleApplicantClick(process)}>
                    {process.jefeZona && getUserName(process.jefeZona.id_jefe_comercial)}
                  </td>
                  <td className="button-container">
                    {
                      (process.jefeZona && getUserName(process.jefeZona.id)) ||
                      <>
                        { isJefeProductoSeleccion &&
                          <button className="asignar-button" onClick={() => assignJefeZona(process)}>Asignar</button>
                        }
                      </>
                    
                    }
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
      <Modal
        open={openModal}
        focusTrapped
        blockScroll={false}
        animationDuration={1}
        closeIconId="video-modal-close-icon"
        onClose={() => {
          setOpenModal(false)
          hideGreyBackground()
        }}
      >
        <div className="react-responsive-modal-title">
          Asignar Jefe de Zona
        </div>
        <div className="react-responsive-modal-subtitle">
          Zona { selectedPostulation.zone }
        </div>
        <div className='radio-buttons-container'>
          {
            jefesZona.map((jz) =>
              <div className='radio' key={jz.rut}>
                {jz.name} <input type="radio" value={jz} name="jefe-zona" checked={jz === chosenJefeZona} onClick={() => setChosenJefeZona(jz)} />
              </div>
            )
          }
        </div>
        <div className="button-container-full-width">
          <button className="action-button hollow" onClick={() => {
              setOpenModal(false)
              hideGreyBackground()}}>
            Cancelar
          </button>
        </div>
        <div className="button-container-full-width">
          <button className="action-button" onClick={saveJefeZona}>Guardar</button>
        </div>
      </Modal>
    </div>
  )
};

export default Processes;
