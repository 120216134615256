import React, { useState, useEffect } from "react"
import axios from 'axios'
import { validateRut, validateEmail, displayLoader, hideLoader } from '../utils.js'
import '../styles/form.scss'

const Form = (props) => {
  const [ rut, setRut ] = useState('')
  const [ name, setName ] = useState('')
  const [ lastName, setLastName ] = useState('')
  const [ bornDay, setBornDay ] = useState('')
  const [ bornMonth, setBornMonth ] = useState('')
  const [ bornYear, setBornYear ] = useState('')
  const [ phone, setPhone ] = useState('')
  const [ email, setEmail ] = useState('')
  const [ profession, setProfession ] = useState('')
  const [ lastJob, setLastJob ] = useState('')
  const [ location, setLocation ] = useState('')
  const [ immediateAvailability, setImmediateAvailability ] = useState('')
  const [ referred, setReferred ] = useState('')
  const [ referredName, setReferredName ] = useState('')
  const [ cv, setCv ] = useState()
  const [ photo, setPhoto ] = useState()
  const [ sentForm, setSentForm ] = useState(false)
  const [ isConcesionarioChange, setIsConcesionarioChange ] = useState(false)
  const [ locations, setLocations ] = useState([])

  const days = Array.from({length: 31}, (_, i) => i + 1)
  const months = [ 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ]
  const years = Array.from({length: 100}, (_, i) => new Date().getFullYear() - i)

  const hiddenCvFileInput = React.useRef(null);
  const hiddenPhotoFileInput = React.useRef(null);

  useEffect(() => {
    if(props.token){
      getLocations()
    }
  }, [props.token]);

  function clearForm(){
    setRut('')
    setName('')
    setLastName('')
    setBornDay('')
    setBornMonth('')
    setBornYear('')
    setPhone('')
    setEmail('')
    setProfession('')
    setLastJob('')
    setLocation(locations[0].localidad)
    setImmediateAvailability('')
    setReferred('')
    setReferredName('')
    setCv()
    setPhoto()
    setIsConcesionarioChange(false)
    setSentForm(false)
  }

  function getLocations(){
    const url = process.env.REACT_APP_BASE_URL + '/workflow/reports/zones'
    fetch(url)
      .then(res => res.json())
      .then((result) => {
        setLocations(result)
        setLocation(result[0].localidad)
      },
      (error) => {
        alert(error);
      }
    )
  }

  const handleUploadCvClick = event => {
    hiddenCvFileInput.current.click();
  };

  const handleUploadPhotoClick = event => {
    hiddenPhotoFileInput.current.click();
  };

  function isFormOk(){
    const monthNumber = months.indexOf(bornMonth) + 1
    const bornDate = bornDay + '/' + monthNumber + '/' + bornYear

    if(!rut || !name || !lastName || !bornDate || !phone || !email || !profession || !lastJob || !location || !immediateAvailability || !referred || !cv || !photo)
      return false
    else
      return true
  }

  async function createProcess(){
    if(!isFormOk()){
      alert("Error. Faltan campos por completar.")
      return
    }

    const monthNumber = months.indexOf(bornMonth) + 1
    const bornDate = bornDay + '/' + monthNumber + '/' + bornYear
    const date = new Date(bornYear, monthNumber - 1, bornDay, 0, 0, 0, 0)
    const today = new Date()
    const age = new Date(today - date).getFullYear() - 1970

    if(age < 18){
      alert("Error. El postulante debe ser mayor de edad.")
      return
    }

    if(!validateRut(rut)){
      alert("Error. Rut inválido.\nIngresa un rut valido con guión y digito verificador.")
      return
    }

    if(!validateEmail(email)){
      alert("Error. Email inválido.")
      return
    }

    if(phone.length < 9){
      alert("Error. Teléfono inválido.")
      return
    }

    const cvFileExtension = cv.name.split('.').pop()
    const photoFileExtension = photo.name.split('.').pop()

    const localidad = locations.find(x => x.localidad === location)

    const data = {
      rut: rut,
      name: name,
      lastName: lastName,
      bornDate: bornDate,
      phone: phone,
      email: email,
      profession: profession,
      lastJob: lastJob,
      location: location,
      zone: localidad.zona,
      isConcesionarioChange: (isConcesionarioChange === 'true'? true: false),
      immediateAvailability: immediateAvailability,
      referred: referred,
      referredName: referredName,
      cvFileType: cv.type,
      photoFileType: photo.type,
      cvFileExtension: cvFileExtension,
      photoFileExtension: photoFileExtension
    }

    displayLoader()

    fetch(process.env.REACT_APP_BASE_URL + "/workflow/processes",
    { 
      method: 'POST',    
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(res => res.json())
    .then(async (result) => {
      if(result.Error){
        alert(result.Error)
        hideLoader()
      }
      else{
        await uploadFiles(result)
        setSentForm(true)
        hideLoader()
      }
    },
    (error) => {
      alert(error);
      hideLoader()
    }
    )
  }

  async function uploadFiles(urls){
    const cvBlob = new Blob([cv], { type: cv.type })
    const photoBlob = new Blob([photo], { type: photo.type })

    await fetch(urls[0], { 
      method: 'PUT',
      body: cvBlob
    })
    .then((result) => {},
      (error) => {
        alert(error);
      }
    )

    await fetch(urls[1], { 
      method: 'PUT',
      body: photoBlob
    })
    .then((result) => {},
      (error) => {
        alert(error);
      }
    )
  }

  function getDateSelects(array, name, value, setFunction){
    return (
      <select value={value} onChange={(e) => setFunction(e.target.value)} >
        <option value='' >{name}</option>
        {array.map((value) => {
          return <option key={value} value={value}>{value}</option>
        })}
      </select>
    )
  }

  return (
    <div>
      <div className='columns'>
        <div className='col-left'>
          <div className='title'>
            Postulación nuevos concesionarios
          </div>
          <div className='subtitle'>
            Te invitamos a ser parte de nuestra red de Concesionarios a lo largo de todo Chile.
          </div>
        </div>
        {
          !sentForm &&
          <div className='col-right'>
            <div className='form-columns'>
              <div className='col'>
                <div className='label'>NOMBRE</div>
                <input type='text' value={name} placeholder='Ingresa nombre' onChange={(e) => setName(e.target.value)} />
              </div>
              <div className='col'>
                <div className='label'>APELLIDO</div>
                <input type='text' value={lastName} placeholder='Ingresa apellido' onChange={(e) => setLastName(e.target.value)} />
              </div>
            </div>

            <div className='form-columns'>
              <div className='col'>
                <div className='label'>RUT <span className="smaller-font">(Ej: 11111111-7)</span></div>
                <input type='text' value={rut} placeholder='Ingresa rut' onChange={(e) => setRut(e.target.value)} />
              </div>
              <div className='col'>
                <div className='label'>FECHA NACIMIENTO</div>
                  { getDateSelects (days, 'Día', bornDay, setBornDay) }
                  { getDateSelects (months, 'Mes', bornMonth, setBornMonth) }
                  { getDateSelects (years, 'Año', bornYear, setBornYear) }
              </div>
            </div>

            <div className='form-columns'>
              <div className='col'>
                <div className='label'>TELÉFONO</div>
                <input type='text' value={phone} placeholder='Ingresa teléfono' onChange={(e) => setPhone(e.target.value)} />
              </div>
              <div className='col'>
                <div className='label'>EMAIL</div>
                <input type='text' value={email} placeholder='Ingresa correo' onChange={(e) => setEmail(e.target.value)} />
              </div>
            </div>

            <div className='form-columns'>
              <div className='col'>
                <div className='label'>PROFESIÓN</div>
                <input type='text' value={profession} placeholder='Ingresa profesión' onChange={(e) => setProfession(e.target.value)} />
              </div>
              <div className='col'>
                <div className='label'>LOCALIDAD A POSTULAR</div>
                <select className="location" value={location} onChange={(e) => setLocation(e.target.value)} >
                  {locations.map((thisLocation) => {
                    return <option key={thisLocation.localidad} value={thisLocation.localidad}>{thisLocation.localidad}</option>
                  })}
                </select>
              </div>
            </div>

            <div className='form-columns'>
              <div className='col'>
                <div className='label'>ADJUNTAR CURRICULUM VITAE</div>
                <button type="button" className="upload-file-button" onClick={handleUploadCvClick}>
                  { cv && cv.name }
                  { !cv && 'Adjuntar archivo' }
                </button>
                <input
                  className='hidden-file-input'
                  type="file"
                  accept=".doc, .docx, .pdf"
                  id="cv"
                  ref={hiddenCvFileInput}
                  onChange={(e) => {
                    setCv(e.target.files[0])
                  }}
                />
              </div>
              <div className='col'>
                <div className='label'>ADJUNTAR FOTOGRAFÍA</div>
                <button type="button" className="upload-file-button" onClick={handleUploadPhotoClick}>
                  { photo && photo.name }
                  { !photo && 'Adjuntar archivo' }
                </button>
                <input
                  className='hidden-file-input'
                  type="file"
                  id="photo"
                  accept="image/*"
                  ref={hiddenPhotoFileInput}
                  onChange={(e) => {
                    setPhoto(e.target.files[0])
                  }}
                />
              </div>
            </div>

            <div className='form-columns'>
              <div className='col'>
                <div className='label'>DISPONIBILIDAD INMEDIATA</div>
                <div>
                  <div className='radio'>
                    <input type="radio" value='si' name="immediateAvailability" checked={immediateAvailability === 'si'} onClick={() => setImmediateAvailability('si')} /> Sí
                  </div>
                  <div className='radio'>
                    <input type="radio" value='no' name="immediateAvailability" checked={immediateAvailability === 'no'} onClick={() => setImmediateAvailability('no')} /> No
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='label'>ES UN CAMBIO DE CONCESIONARIO</div>
                <select value={isConcesionarioChange} onChange={(e) => setIsConcesionarioChange(e.target.value)} >
                  <option value={true}>Si</option>
                  <option value={false}>No</option>
                </select>
              </div>
            </div>
            <div className='form-columns'>
              <div className='col'>
                <div className='label'>REFERIDO</div>
                <div className='radio-buttons-container'>
                  <div className='radio'>
                    <input type="radio" value='si' name="referred" checked={referred === 'si'} onClick={() => setReferred('si')} /> Sí
                  </div>
                  <div className='radio'>
                    <input type="radio" value='no' name="referred" checked={referred === 'no'} onClick={() => setReferred('no')} /> No
                  </div>
                </div>
              </div>
              {referred === 'si' &&
                <div className='col'>
                  <div className='label'>NOMBRE DEL REFERIDO</div>
                  <input type='text' value={referredName} placeholder='Ingresa nombre' onChange={(e) => setReferredName(e.target.value)} />
                </div>
              }
            </div>

            <div className='label'>ULTIMO TRABAJO</div>
            <textarea value={lastJob} placeholder='Empresa, año de inicio, año de término y breve descripción del cargo realizado' onChange={(e) => setLastJob(e.target.value)} />

            <button className={`apply-button ${isFormOk()? 'enabled': ''}`} onClick={createProcess}>Postular</button>
          </div>
        }
        {
          sentForm &&
          <div className='col-right'>
            <div className="sent-postulation-message">
              <div className="check" />
              <div className="message">
                <div className="line">
                  Gracias por su postulación,
                </div>
                <div className="line">
                  Nos pondremos en contacto
                </div>
              </div>
              <button onClick={clearForm} className="back-button back-button-form">Volver al sitio</button>
            </div>
          </div>
        }
      </div>
    </div>
  )
};

export default Form;
