import React, { Fragment, useState, useEffect } from "react";
import { displayLoader, hideLoader, parseDate, parseTime } from "../utils.js";

const Comments = ({ token, applicant, comments, id, getHistorial }) => {
  const [ comment, setComment ] = useState('')

  async function uploadComment(){
    displayLoader()
    const data = {
      id: id,
      comment: comment
    }

    await fetch(process.env.REACT_APP_BASE_URL + "/workflow/comments",
    {
      method: 'POST',    
      headers: {
        'Content-Type': 'application/json',
        'token': token
      },
      body: JSON.stringify(data)
    })
    .then((result) => {
      getHistorial()
      setComment('')
      },
      (error) => {
        alert(error);
      }
    )
    hideLoader()
  }


  return (
    <div className='comments'>
      <div className="comments-title">OBSERVACIONES</div>
      <textarea value={comment} rows="4" maxLength="500" onChange={(e) => setComment(e.target.value)} />
      <div className="textarea-counter">{comment? comment.length: 0}/500</div>
      <button className="custom-button blue-button comment-button" onClick={uploadComment}>Comentar</button>
      <div className="comments-container">
        <div className="comments-container__title">OBSERVACIONES RECIENTES</div>
        {
          comments.map((comment, index) => {
            return(<div key={index} className="comments-container__comment">
              <div className='comments-container__name'>{comment.name}</div>
              <div className="comments-container__text">{comment.data}</div>
              <div className="comments-container__date">{parseDate(comment.date)}</div>
              <div className="comments-container__time">{parseTime(comment.date)}</div>
            </div>)
          })
        }
      </div>
    </div>
  )
};

export default Comments;
