import React, { Fragment, useState, useEffect } from "react"
import { getPresignedUrlForGet, 
  displayLoader, 
  hideLoader, 
  dynamicSort, 
  parseDate, 
  setHistorialData,
  pasos } from "../utils.js"
import { dictionary } from '../dictionary.js'
import useDynamicRefs from 'use-dynamic-refs'
import '../styles/applicant.scss';
import '../styles/historial.scss'

const Historial = ({token, applicant, historial, comments}) => {
  const [data, setData] = useState([])
  const [startProcessDate, setStartProcessDate] = useState('')
  const [totalProcessTime, setTotalProcessTime] = useState('')

  useEffect(() => {
    if(historial){
      setHistorialData(historial, setStartProcessDate, setTotalProcessTime, setData)
    }
  }, [historial]);

  function getStepName(stepName, stepNumber){
    if(!stepName || stepName === '')
      return 'Paso ' + stepNumber

    const paso = pasos.find(p => p.stepName === stepName)
    return paso.label
  }

  return (
    <div className='historial'>   
      <div className='historial-entry'>
        <div>
          <div className='start-process'>Fecha de inicio de proceso {startProcessDate}</div>
          <div className='start-process'>Tiempo total en proceso {totalProcessTime}</div>
        </div>
      </div>
      { 
        data.map((entry, index) => {
          return(
            <div className='historial-entry'>
              <div>
                <div className="step-name">{getStepName(entry.stepName, entry.step)}</div>
                <div className="step-info">
                  <div className="total-step-time">Tiempo total paso: {entry.totalStepTime}</div>
                  Fecha Inicio &nbsp;&nbsp;{entry.startDate}<br />
                  Fecha Fin &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{entry.endDate}
                </div>
              </div>
            </div>
          )
        })
      }
      <hr />
    </div>
  )
};

export default Historial;
