import React, { useState, useEffect } from "react";
import { dictionary } from '../dictionary.js'
import Comments from './Comments'
import Carton from './Carton'
import useDynamicRefs from 'use-dynamic-refs'
import DatePicker from "react-datepicker"
import history from "../history"
import { Modal } from "react-responsive-modal"
import clip_on from "../assets/img/clip_on.svg"
import alerta_icon from "../assets/img/alerta_grande.png"
import success_icon from "../assets/img/success_icon.png"
import alerta_ok from "../assets/img/alerta_ok.svg"
import cross_icon from "../assets/img/cross-icon.svg"
import 'react-responsive-modal/styles.css';
import '../styles/modal.scss'

import { uploadFile, 
  getPresignedUrlForGet,
  deleteMultipleFilesItemUploaded,
  displayLoader, 
  hideLoader, 
  dynamicSort, 
  parseDate, 
  unparseDate,
  getRemainingTime, 
  getTotalTime, 
  getFileExtension,
  showGreyBackground,
  hideGreyBackground,
  parentRedirect,
  scrollTo,
  getFileTypeIcon } from "../utils.js"

const Step = ({ token,
  applicant, 
  inputs, 
  extras, 
  textInputs, 
  setTextInputs, 
  fileInputs, 
  multipleFileInputs, 
  dateInputs,
  tableCombustiblesInputs,
  tableCapitalTrabajoInputs, 
  tableSociosInputs,
  choices,
  id,
  waitingForCompromises,
  waitingForCartonSign,
  checkSingleWorkflowStatus,
  step,
  disableChanges,
  goPreviousStep,
  goNextStep,
  setDateInputs,
  setMultipleFileInputs,
  getProcess,
  getHistorial,
  setFileInputs,
  setTableCombustiblesInputs,
  setTableCapitalTrabajoInputs,
  setTableSociosInputs,
  downloadFile,
  comments,
  enabledSaveButton,
  setEnabledSaveButton }) => {

  const [ getRef, setRef ] =  useDynamicRefs()
  const [ showChoices, setShowChoices ] = useState(false)
  const [ openModal, setOpenModal ] = useState(false)
  const [ openRejectionModal, setOpenRejectionModal ] = useState(false)
  const [ openSuccessModal, setOpenSuccessModal ] = useState(false)
  const [ modalStep, setModalStep ] = useState(1)
  const [ rejectionModalStep, setRejectionModalStep ] = useState(1)
  const [ mailRechazo, setMailRechazo ] = useState("")
  const [ keyForRejection, setKeyForRejection ] = useState()

  useEffect(() => {
    if(applicant && inputs){
      initializeInputs()
    }
  }, [applicant, inputs]);

  function initializeInputs(){
    const newDateInputs = {}
    const newTextInputs = {}

    Object.entries(inputs).map(([key, value]) => {    
      if(applicant[value.key]){
        if(value.type === 'date' || value.type === 'mes')
          newDateInputs[value.key] = unparseDate(applicant[value.key])

        else if(value.type === 'text' 
          || value.type === 'textarea'
          || value.type === 'number'
          || value.type === 'certificacionGrade'
          || value.type === 'eds'
          || value.type === 'expectedIncome'
          || value.type === 'grade'
          || value.type === 'check'
        )
          newTextInputs[value.key] = applicant[value.key]
      }    
    })

    if(applicant["PromedioVentasMesDesde"])
      newTextInputs["PromedioVentasMesDesde"] = applicant["PromedioVentasMesDesde"]

    console.log("NEW TEXT INPUTS ES::")
    console.log(newTextInputs)

    setDateInputs(newDateInputs)
    setTextInputs(newTextInputs)
    checkShowChoices(inputs, applicant)
  }

  function deleteItemFromMultipleFiles(key, index){
    const tmpMultipleFileInputs = multipleFileInputs[key]
    tmpMultipleFileInputs.splice(index, 1)
    setMultipleFileInputs({
      ...multipleFileInputs,
      [key]: tmpMultipleFileInputs
    })
  }

  async function deleteItemFromMultipleFilesUploaded(key, index){
    displayLoader()
    await deleteMultipleFilesItemUploaded(key, index, applicant.id, token)
    getProcess()
    hideLoader()
  }

  function downloadLocalFile(obj){
    var tmppath = URL.createObjectURL(obj);
    window.open(tmppath, "_blank")
  }

  async function saveChanges(){
    displayLoader()
    let valid = true

    for await (const file of Object.entries(fileInputs)) {
      await uploadFile(file[1], file[0], id, token, false)
    }

    for await (const files of Object.entries(multipleFileInputs)) {
      for await (const file of files[1]) {
        await uploadFile(file, files[0], id, token, true)

      }
    }

    const data = {
      id: id
    }

    Object.entries(dateInputs).map(([key, value]) => {
      data[key] = parseDate(value)
    })

    Object.entries(textInputs).map(([key, value]) => {
      data[key] = value
    })

    Object.entries(inputs).map(([key, value]) => {
      if(value.key === 'CuadroCapitalTrabajo'){
        data[value.key] = tableCapitalTrabajoInputs

        Object.entries(tableCapitalTrabajoInputs).map(([key, value]) => {
          if(value === '0' || value === 0)
            valid = false
        })
      }
      else if(value.key === 'CuadroVentasCombustiblesLubricantes'){
        data[value.key] = tableCombustiblesInputs
      }

      else if(value.key === 'TableSocios'){
        data[value.key] = tableSociosInputs
      }
    })

    let response = false

    if(!valid){
      alert("El capital de trabajo no puede ser 0")
    }
    else{
      await fetch(process.env.REACT_APP_BASE_URL + "/workflow/processes",
      {
        method: 'PUT',    
        headers: {
          'Content-Type': 'application/json',
          'token': token
        },
        body: JSON.stringify(data)
      })
      .then((result) => {
        setOpenSuccessModal(true)
        showGreyBackground()
        response = true
        scrollTo(0, 100)
        },
        (error) => {
          alert(error);
        }
      )

      setFileInputs({})
      setMultipleFileInputs({})
      setTextInputs({})
      setDateInputs({})
      setEnabledSaveButton(false)

      if(step !== applicant.step)
        getProcess(step)
      else
        getProcess()
    }
    hideLoader()
  }

  async function nextStep(choice){
    displayLoader()
    let url = process.env.REACT_APP_BASE_URL + '/workflow/step_succeed'

    const params = {
      id: id,
      ResultadoAnalisis: choice,
      taskToken: applicant.taskToken
    }
    
    await fetch(url, 
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'token': token
      },
      body: JSON.stringify(params)
    })
    .then(res => res.json())
    .then((result) => {
      setFileInputs({})
      setMultipleFileInputs({})
      setTextInputs({})
      setDateInputs({})
    },
      (error) => {
        alert(error);
      }
    )

    if(!(applicant.jefeZona && applicant.jefeZona.name)){
      hideLoader()
      parentRedirect(process.env.REACT_APP_POSTULACIONES_PATH)
      return
    }

    getProcess()
    getHistorial()
  }

  function rechazoCarton(key){
    setKeyForRejection(key)
    setOpenRejectionModal(true)
    setRejectionModalStep(1)
    showGreyBackground()
    scrollTo(0, 100)
  }

  function closeCartonModal(key){
    setKeyForRejection()
    setOpenRejectionModal(false)
    setRejectionModalStep(1)
    hideGreyBackground()
  }

  function reject(){
    setOpenModal(true)
    showGreyBackground()
    setModalStep(1)
    setMailRechazo("")
  }

  const handleFileUploadClick = (event, key) => {
    getRef(key).current.click()
  }

  function setFileInputsChange(file, key){
    setEnabledSaveButton(true)
    setFileInputs({
      ...fileInputs,
      [key]: file
    })
  }

  function setMultipleFileInputsChange(file, key){
    setEnabledSaveButton(true)
    let tmpFileInputs = multipleFileInputs[key]
    if(!tmpFileInputs)
      tmpFileInputs = []

    tmpFileInputs.push(file)
    setMultipleFileInputs({
      ...multipleFileInputs,
      [key]: tmpFileInputs
    })
  }

  function setDateInputsChange(date, key){
    setEnabledSaveButton(true)
    setDateInputs({
      ...dateInputs,
      [key]: date
    })
  }

  function tableCombustiblesChange(value, fuel, month){
    const newMonth = tableCombustiblesInputs[fuel]
    newMonth[month] = value

    let newTotal = 0

    Object.entries(tableCombustiblesInputs).map(([key, value]) => {
      if(key !== 'Total' && value[month] !=='')
        newTotal += parseInt(value[month])
    })

    const newTotals = tableCombustiblesInputs['Total']
    newTotals[month] = newTotal
    
    setEnabledSaveButton(true)
    setTableCombustiblesInputs({
      ...tableCombustiblesInputs,
      [fuel]: newMonth,
      ['Total']: newTotals
    })
  }

  function tableCapitalTrabajoChange(value, key){
    setEnabledSaveButton(true)
    setTableCapitalTrabajoInputs({
      ...tableCapitalTrabajoInputs,
      [key]: value
    })
  }

  function tableSociosChange(key, name, value){
    const newTableSocios = [...tableSociosInputs]
    newTableSocios[key][name] = value
    setTableSociosInputs(newTableSocios)
  }

  function setTextInputsChange(value, key, type = null){
    if(type === 'check'){
      if(value){
        value = 'si';
      }
      else{
        value = 'no';
      }
    }
    else if(type === 'grade'){
      value = value.replace('.', '')
      let decimal = value.replace(',', '.')
      if(decimal.split('.')[1] && decimal.split('.')[1].length > 1)
        return

      if(isNaN(decimal))
        return

      if(parseFloat(decimal) > 7 || parseFloat(decimal) < 1)
        return
    }
    else if(type === 'certificacionGrade'){
      if(parseInt(value) > 100 || parseInt(value) < 0){
        return;
      }
    }
    else if(type === 'eds'){
      if(parseInt(value) > 99999 || parseInt(value) < 0){
        return;
      }
    }
    else if(type === 'expectedIncome'){
      if(parseInt(value) > 99999999 || parseInt(value) < 0){
        return;
      }
    }

    setEnabledSaveButton(true)
    setShowChoices(false)
    setTextInputs({
      ...textInputs,
      [key]: value
    })
  }

  function cancel(){
    initializeInputs()
    setEnabledSaveButton(false)
  }

  function checkShowChoices(newInputs, newApplicant){
    let found = true
    Object.entries(newInputs).map(([key, value]) => {
      if(!newApplicant[value.key]){
        if(newApplicant.step === parseInt(process.env.REACT_APP_CARTON_FORM) && newApplicant.numHipotecas === '1'){
          if(value.key !== 'CiudadOtroBienRaiz' && value.key !== 'DireccionOtroBienRaiz' && value.key !== 'ValorOtroBienRaiz' && value.key !== 'ValorLiquidacionOtroBienRaiz')
            found = false
        }
        else
          found = false
      }
      if(value.type === 'check' && newApplicant[value.key] !== 'si'){
        found = false
      }
    })

    if(!found){
      setShowChoices(false)
      return
    }

    setShowChoices(true)
  }

  function closeRejectionModal(){
    setOpenModal(false)
    setModalStep(1)
    setMailRechazo("")
    hideGreyBackground()
  }

  function goToStep2Modal(){
    setModalStep(2)
    getRejectionEmail()
  }

  async function getRejectionEmail(){
    displayLoader()
    const url = process.env.REACT_APP_BASE_URL + '/workflow/rejection?id=' + applicant.id
    await fetch(url, { headers: { token: token }})
      .then(res => res.json())
      .then((result) => {
        setMailRechazo(result)
      },
      (error) => {
        alert(error);
      }
    )
    hideLoader()
  }

  async function submitRejection(){
    displayLoader()
    const data = {
      id: applicant.id
    }

    const url = process.env.REACT_APP_BASE_URL + '/workflow/rejection'

    await fetch(url, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(
        (result) => {
          hideGreyBackground()
          hideLoader()
          parentRedirect(process.env.REACT_APP_POSTULACIONES_PATH)
        },
        (error) => {
          alert(error)
        }
      )

    hideGreyBackground()
    hideLoader()
  }

  function getFileName(obj){
    if(fileInputs[obj])
      return fileInputs[obj].name

    return applicant[obj]
  }

  function closeSuccessModal(){
    setOpenSuccessModal(false)
    hideGreyBackground()
  }

  return (
    <div className='step'>
      <div className="step-summary">
        <h1>{ dictionary[applicant.stepName] }</h1>
        <h2>Paso { step } de { process.env.REACT_APP_TOTAL_STEPS }</h2>
        <h2>Días en proceso: { getTotalTime(applicant.startDate) } días</h2>
        { applicant.stepResponsible && 
          <h2>
            Responsables:&nbsp;
            {
              applicant.stepResponsible.map((responsible, i, row) =>
              <span>{responsible}{(i + 1 !== row.length)? ', ': ''}</span>
            )}
          </h2>
        }
        {/*<h3>Tiempo restante { getRemainingTime(applicant.stepStartDate, applicant.stepTimeLimit) }</h3>*/}
      </div>

      <div className="section">
        <div className="section-title"><span>DESCRIPCIÓN</span></div>
        <div className="description" dangerouslySetInnerHTML={{__html: applicant.description}} />

        {
          applicant.dataTitle &&
          <div className="section-title data-title"><span>{ applicant.dataTitle }</span></div>
        }
        {
          !applicant.dataTitle && Object.entries(inputs).length > 0 &&
          <hr className="data-title-replacement" />
        }
        
      </div>

      {!waitingForCompromises && parseInt(process.env.REACT_APP_CARTON_FORM) !== step &&
        <div className="step-form">
          {
            Object.entries(inputs).map(([key, obj]) => 
              <>
                {
                  obj.type !== 'check' &&
                  <div className='data-title-dynamic required'>{ dictionary[obj.key] }
                    {obj.type === 'certificacionGrade' && <span className="grade-info-title"> (de 0 a 100)</span>}
                    {obj.type === 'grade' && <span className="grade-info-title"> (de 1 a 7)</span>}
                  </div>
                }
                { !disableChanges &&
                  <>
                    {
                      (obj.type === 'number' || obj.type === 'certificacionGrade' || obj.type === 'eds' || obj.type === 'expectedIncome') &&
                      <div lang="es">
                        <input className="text-input" type='number' value={textInputs[obj.key] || ''} onChange={(e) => setTextInputsChange(e.target.value, obj.key, obj.type)} />
                      </div>
                    }

                    {
                      obj.type === 'grade' &&
                      <div lang="es">
                        <input className="text-input" type='text' value={textInputs[obj.key] || ''} onChange={(e) => setTextInputsChange(e.target.value, obj.key, obj.type)} />
                      </div>
                    }

                    {
                      obj.type === 'date' &&
                      <DatePicker
                        onChange={(date) => setDateInputsChange(date, obj.key)}
                        dateFormat="dd/MM/yyyy"
                        selected={dateInputs[obj.key]}
                      />
                    }
                    {
                      obj.type === 'mes' &&
                      <DatePicker
                        onChange={(date) => setDateInputsChange(date, obj.key)}
                        dateFormat="MM/yyyy"
                        selected={dateInputs[obj.key]}
                        showMonthYearPicker
                      />
                    }
                    {
                      obj.type === 'file' &&
                      <>
                        <button type="button" className="custom-button blue-button-hollow upload-file" onClick={(e) => handleFileUploadClick(e, obj.key)}>
                          <img src={clip_on} />
                          <span>Adjuntar Archivo</span>
                        </button>
                        <input
                          className='hidden-file-input'
                          type="file"
                          id="file"
                          ref={setRef(obj.key)}
                          onChange={(e) => setFileInputsChange(e.target.files[0], obj.key)}
                        />

                        { (fileInputs[obj.key] || applicant[obj.key]) && 
                          <div className="uploaded-file" onClick={() => downloadFile(applicant[obj.key])}>
                            <div className="file-type-container">
                              <img src={getFileTypeIcon(getFileExtension(getFileName(obj.key, '')))} />
                            </div>
                            <div className="file-info-container">
                              <div className="title">
                                {dictionary[obj.key]}
                              </div>
                              <div className="file-type">
                                Archivo {fileInputs[obj.key]? getFileExtension(fileInputs[obj.key].name): getFileExtension(applicant[obj.key])}
                              </div>
                            </div>
                          </div>
                        }
                      </>
                    }
                    {
                      obj.type === 'multipleFiles' &&
                      <>
                        {
                          applicant[obj.key] && applicant[obj.key].map(f => {
                            return (
                              <div className="uploaded-file">
                                <div className="file-type-container">
                                  <img src={getFileTypeIcon(getFileExtension(f))} />
                                </div>
                                <div className="file-info-container" onClick={() => downloadFile(f)}>
                                  <div className="title">
                                    {dictionary[obj.key]}
                                  </div>
                                  <div className="file-type">
                                    Archivo {getFileExtension(f)}
                                  </div>
                                </div>
                                <img className="uploaded-file-delete" src={cross_icon} onClick={() => deleteItemFromMultipleFilesUploaded(obj.key, f)} />
                              </div>
                            )
                          })
                        }

                        {
                          multipleFileInputs[obj.key] && multipleFileInputs[obj.key].map((f, fkey) => {
                            return (
                              <div className="uploaded-file">
                                <div className="file-type-container">
                                  <img src={getFileTypeIcon(getFileExtension(f.name))} />
                                </div>
                                <div className="file-info-container" onClick={() => downloadLocalFile(f)}>
                                  <div className="title">
                                    {dictionary[obj.key]}
                                  </div>
                                  <div className="file-type">
                                    Archivo {getFileExtension(f.name)}
                                  </div>
                                </div>
                                <img className="uploaded-file-delete" src={cross_icon} onClick={() => deleteItemFromMultipleFiles(obj.key, fkey)} />
                              </div>
                            )
                          })
                        }

                        <button type="button" className="custom-button blue-button-hollow upload-file" onClick={(e) => handleFileUploadClick(e, obj.key)}>
                          <img src={clip_on} />
                          {((applicant[obj.key] && applicant[obj.key].length > 0) || 
                            (multipleFileInputs[obj.key] && multipleFileInputs[obj.key].length > 0)) &&
                            <span>Adjuntar Otro Archivo</span>
                          }
                          {!((applicant[obj.key] && applicant[obj.key].length > 0) || 
                            (multipleFileInputs[obj.key] && multipleFileInputs[obj.key].length > 0)) &&
                            <span>Adjuntar Archivo</span>
                          }
                        </button>
                        <input
                          className='hidden-file-input'
                          type="file"
                          id="file"
                          ref={setRef(obj.key)}
                          onChange={(e) => setMultipleFileInputsChange(e.target.files[0], obj.key)}
                        />
                      </>
                    }
                    {
                      obj.type === "textarea" &&
                      <>
                        <textarea className="text-input" value={textInputs[obj.key] || ''} onChange={(e) => setTextInputsChange(e.target.value, obj.key)} rows="4" maxLength="500" />
                        <div className="textarea-counter">{textInputs[obj.key]? textInputs[obj.key].length: 0}/500</div>
                      </>
                    }

                    {
                      obj.type === "text" &&
                      <input type="text" className="text-input" value={textInputs[obj.key]} onChange={(e) => setTextInputsChange(e.target.value, obj.key, obj.type)} />      
                    }

                    {
                      obj.type === 'check' &&
                      <div className="checkbox-input-container">
                        <input type='checkbox' checked={textInputs[obj.key] === 'si'? "checked" : ""} onChange={(e) => setTextInputsChange(e.target.checked, obj.key, "check")} />
                        <div className='data-title-dynamic required'>{ dictionary[obj.key] }</div>
                      </div>
                    }

                    {
                      obj.type === 'tableCapitalTrabajo' &&
                      <>
                        <table className="tabla-copec-1 capital-trabajo-table">
                          <thead>
                            <tr>
                              {Object.entries(tableCapitalTrabajoInputs).map(([key, value]) => 
                                <th>{key}</th>
                              )}
                            </tr>
                          </thead>
                          <tr className="tabla-copec-1-separator"><td colSpan="3" /></tr>
                          <tbody>
                            <tr>
                              {Object.entries(tableCapitalTrabajoInputs).map(([key, value]) => 
                                <td className="capital-trabajo-table-td"><input value={tableCapitalTrabajoInputs[key]} type="text" onChange={(e) => tableCapitalTrabajoChange(e.target.value, key)} /></td>
                              )}
                            </tr>
                          </tbody>
                        </table>

                        {
                          !applicant['tableCapitalTrabajo'] &&
                          <div className="missing-field">Este campo es obligatorio</div>
                        }
                      </>
                    }

                    {
                      obj.type === 'tableCombustibles' &&
                      <>
                        <table className="tabla-copec-1 combustibles-table">
                          <thead>
                            <tr>
                              <th><div className="th-data">Mes</div></th>
                              {
                                Object.entries(tableCombustiblesInputs).map(([fuelValue, obj]) => 
                                <th><div className="th-data">{fuelValue}</div></th>)
                              }
                            </tr>
                          </thead>
                          <tr className="tabla-copec-1-separator"><td colSpan="7" /></tr>
                          <tbody>
                            {
                              [...Array(6).keys()].map((monthValue) => 
                                <>
                                  <tr>
                                    <td className="center">{monthValue + 1}</td>
                                    {
                                      Object.entries(tableCombustiblesInputs).map(([fuelValue, obj]) => 
                                        <td>
                                          { fuelValue !== 'Total' &&
                                            <input type="number" value={tableCombustiblesInputs[fuelValue][monthValue + 1]} onChange={(e) => tableCombustiblesChange(e.target.value, fuelValue, monthValue + 1)} />
                                          }
                                          { fuelValue == 'Total' &&
                                            <div className="fuel-total">{tableCombustiblesInputs[fuelValue][monthValue + 1]}</div>
                                          }
                                        </td>
                                      )
                                    }
                                  </tr>
                                  <tr className="tabla-copec-1-tbody-separator"><td colSpan="7"><div className="tabla-copec-1-tbody-separator-inner" /></td></tr>
                                </>
                              )
                            }
                          </tbody>
                        </table>
                        {
                          !applicant['tableCombustibles'] &&
                          <div className="missing-field">Este campo es obligatorio</div>
                        }
                      </>
                    }
                  </>
                }

                { disableChanges && 
                  <>
                    {(obj.type === 'number' || 
                      obj.type === 'certificacionGrade' || 
                      obj.type === 'eds' || 
                      obj.type === 'expectedIncome' || 
                      obj.type === 'grade' ||
                      obj.type === "textarea" ||
                      obj.type === "text" ||
                      obj.type === 'check' ||
                      obj.type === 'date' ||
                      obj.type === 'mes') &&
                      <span>{applicant[obj.key]}</span>
                    }
                    {obj.type === 'file' &&
                      <div className="uploaded-file" onClick={() => downloadFile(applicant[obj.key])}>
                        <div className="file-type-container">
                          <img src={getFileTypeIcon(getFileExtension(getFileName(obj.key, '')))} />
                        </div>
                        <div className="file-info-container">
                          <div className="title">
                            {dictionary[obj.key]}
                          </div>
                          <div className="file-type">
                            Archivo {getFileExtension(applicant[obj.key])}
                          </div>
                        </div>
                      </div>
                    }
                    {obj.type === 'multipleFiles' &&
                      applicant[obj.key] && applicant[obj.key].map(f => {
                        return (
                          <div className="uploaded-file">
                            <div className="file-type-container">
                              <img src={getFileTypeIcon(getFileExtension(f))} />
                            </div>
                            <div className="file-info-container" onClick={() => downloadFile(f)}>
                              <div className="title">
                                {dictionary[obj.key]}
                              </div>
                              <div className="file-type">
                                Archivo {getFileExtension(f)}
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                    {obj.type === 'tableCapitalTrabajo' &&
                      <table className="tabla-copec-1 capital-trabajo-table">
                        <thead>
                          <tr>
                            {Object.entries(tableCapitalTrabajoInputs).map(([key, value]) => 
                              <th>{key}</th>
                            )}
                          </tr>
                        </thead>
                        <tr className="tabla-copec-1-separator"><td colSpan="3" /></tr>
                        <tbody>
                          <tr>
                            {Object.entries(tableCapitalTrabajoInputs).map(([key, value]) => 
                              <td className="capital-trabajo-table-td">{tableCapitalTrabajoInputs[key]}</td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    }

                    {obj.type === 'tableCombustibles' &&
                      <table className="tabla-copec-1 combustibles-table">
                        <thead>
                          <tr>
                            <th><div className="th-data">Mes</div></th>
                            {
                              Object.entries(tableCombustiblesInputs).map(([fuelValue, obj]) => 
                              <th><div className="th-data">{fuelValue}</div></th>)
                            }
                          </tr>
                        </thead>
                        <tr className="tabla-copec-1-separator"><td colSpan="7" /></tr>
                        <tbody>
                          {
                            [...Array(6).keys()].map((monthValue) => 
                              <>
                                <tr>
                                  <td className="center">{monthValue + 1}</td>
                                  {
                                    Object.entries(tableCombustiblesInputs).map(([fuelValue, obj]) => 
                                      <td>
                                        { fuelValue !== 'Total' &&
                                          <span>{tableCombustiblesInputs[fuelValue][monthValue + 1]}</span>
                                        }
                                        { fuelValue == 'Total' &&
                                          <div className="fuel-total">{tableCombustiblesInputs[fuelValue][monthValue + 1]}</div>
                                        }
                                      </td>
                                    )
                                  }
                                </tr>
                                <tr className="tabla-copec-1-tbody-separator">
                                  <td colSpan="7"><div className="tabla-copec-1-tbody-separator-inner" /></td>
                                </tr>
                              </>
                            )
                          }
                        </tbody>
                      </table>
                    }
                  </>
                }
              </>
            )
          }
          <hr className="choices-separator" />
        </div>
      }

      {!waitingForCompromises && !waitingForCartonSign &&
        <>
          {
            (extras.includes("DisplayCarton") || parseInt(process.env.REACT_APP_CARTON_FORM) === step)  &&
            <Carton
              applicant = { applicant }
              tableCombustiblesInputs = { tableCombustiblesInputs }
              tableSociosInputs = { tableSociosInputs }
              tableCapitalTrabajoInputs = { tableCapitalTrabajoInputs }
              tableSociosChange = { tableSociosChange }
              textInputs = { textInputs }
              setTextInputsChange = { setTextInputsChange }
            />
          }

          { !disableChanges && Object.entries(inputs).length > 0 && 
            <div className='action-buttons-container'>
              <button className='custom-button blue-button-hollow save-changes clear-button' onClick={cancel} disabled={!enabledSaveButton}>Limpiar</button>
              <button className='custom-button blue-button save-changes' onClick={saveChanges} disabled={!enabledSaveButton}>Guardar</button>
            </div>
          }

          { step === applicant.step &&
            <div className='choices'>
              {
                Object.entries(choices).map(([key, value]) => 
                  <>
                    { key === "Rechazo" &&
                      <button className="custom-button red-button-hollow choices-button" onClick={reject} key={key} disabled={!showChoices}>Rechazar</button>
                    }
                    { key !== "Rechazo" && value !== "Rechazo" &&
                      <button className='custom-button red-button choices-button' onClick={() => nextStep(key)} key={key} disabled={!showChoices}>{value}</button>
                    }
                    { value === "Rechazo" && parseInt(process.env.REACT_APP_CARTON_SIGN_FIRST_STEP) <= applicant.step && parseInt(process.env.REACT_APP_CARTON_SIGN_FIRST_STEP) + 3 >= applicant.step &&
                      <button className='custom-button red-button choices-button' onClick={() => rechazoCarton(key)} key={key} disabled={!showChoices}>{value}</button>
                    }
                  </>
                )
              }
            </div>
          }
          <div className='step-choices'>
            { step !== 1 &&
              <button className="custom-button blue-button choices-button" onClick={goPreviousStep}>Paso Anterior</button>
            }
            { step !== 17 && step !== applicant.step &&
              <button className="custom-button blue-button choices-button" onClick={goNextStep}>Paso Siguiente</button>
            }
          </div>
        </>
      }
      {
        waitingForCompromises &&
        <>
          <div className="parallel-step-webdox-error no-alert-img">
            No se ha completado la firma de cartas de compromiso
          </div>
          <div className='choices'>
            <button 
              className="custom-button red-button-hollow choices-button" 
              onClick={() => checkSingleWorkflowStatus(applicant.id)} 
              disabled={applicant.checking_workflow_status}
            >
              Revisar estado Webdox
            </button>
          </div>
        </>
      }

      {
        waitingForCartonSign &&
        <>
          {
            applicant.workflows.carton.signers.length === 0 &&
            <div className="waiting-parallel-step-alert waiting-parallel-step-alert-extra-margin-bottom">
              <span className="red">Importante: </span>
              Se esta generando el carton de nombramiento. Por favor espera un momento y recarga la pagina.
            </div>
          }
          {
            applicant.workflows.carton.signers.length > 0 &&

            <div className="parallel-step-webdox-error no-alert-img">
              {`${applicant.workflows.carton.signers[applicant.step - parseInt(process.env.REACT_APP_CARTON_SIGN_FIRST_STEP) - 1].name} 
              ${applicant.workflows.carton.signers[applicant.step - parseInt(process.env.REACT_APP_CARTON_SIGN_FIRST_STEP) - 1].lastName} 
              tiene que completar la firma del carton para poder continuar`}
            </div>
          }
          
          <div className='choices'>
            <button 
              className="custom-button red-button-hollow choices-button" 
              onClick={() => checkSingleWorkflowStatus(applicant.id)} 
              disabled={applicant.checking_workflow_status}
            >
              Revisar estado Webdox
            </button>
          </div>
        </>
      }
      <div className="bottom-step-number">
        Paso { step } de { process.env.REACT_APP_TOTAL_STEPS }
      </div>

      <Comments
        getHistorial = { getHistorial }
        comments = { comments }
        id = { id }
        token = { token }
      />
      <Modal
        open={openModal}
        focusTrapped
        blockScroll={false}
        animationDuration={1}
        closeIconId="video-modal-close-icon"
        onClose={closeRejectionModal}
      >
        <div className="modal-rechazo-container">
          { modalStep === 1 &&
            <>
              <img className="big-icon" src={alerta_icon} />
              <div className="modal-title">
                Rechazar Postulación
              </div>
              <div className="modal-info">
                ¿Está seguro que desea rechazar la postulación? De continuar, se remitirá correo a postulante indicando el cierre del proceso.
              </div>
              <hr />
              <button className="save-button rechazo-button rechazo-button-hollow" onClick={closeRejectionModal}>Cancelar</button>
              <button className="save-button rechazo-button" onClick={goToStep2Modal}>Aceptar</button>
            </>
          }
          { modalStep === 2 &&
            <>
              <div className="modal-title2">
                Rechazo de postulación
              </div>
              <div className="modal-subtitle">
                Mail al postulante
              </div>
              <div className="modal-info mail-rechazo" dangerouslySetInnerHTML={{__html: mailRechazo }} />
              <hr />
              <button className="custom-button red-button-hollow" onClick={closeRejectionModal}>Cancelar</button>
              <button className="custom-button red-button" onClick={submitRejection}>Enviar</button>
            </>
          }
        </div>
      </Modal>
      <Modal
        open={openRejectionModal}
        focusTrapped
        blockScroll={false}
        animationDuration={1}
        closeIconId="video-modal-close-icon"
        onClose={closeCartonModal}
      >
        <div className="modal-rechazo-container">
          <img className="big-icon" src={alerta_icon} />
          <div className="modal-title">
            Nombramiento Rechazado
          </div>
          <div className="modal-info">
            ¿Está seguro que desea rechazar?
          </div>
          <hr />
          <button className="save-button rechazo-button rechazo-button-hollow" onClick={closeCartonModal}>Cancelar</button>
          <button className="save-button rechazo-button" onClick={() => nextStep(keyForRejection)}>Aceptar</button>
        </div>
      </Modal>

      <Modal
        open={openSuccessModal}
        focusTrapped
        blockScroll={false}
        animationDuration={1}
        closeIconId="video-modal-close-icon"
        onClose={closeSuccessModal}
      >
        <div className="modal-rechazo-container">
          <img className="big-icon" src={success_icon} />
          <div className="modal-title">
            Guardado con éxito
          </div>
          <div className="modal-info">
            Los cambios han sido guardados<br />exitosamente
          </div>
          <hr />
          <button className="save-button rechazo-button" onClick={closeSuccessModal}>Aceptar</button>
        </div>
      </Modal>
    </div>
  )
};

export default Step;
