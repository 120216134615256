import React, { useState, useEffect } from "react"
import DatePicker from "react-datepicker"
import ExportExcel from "./ExportExcel"

import { 
  displayLoader,
  hideLoader,
  parseDate,
  SingleOption,
  customStylesSelect,
  getDatesHoursDifference,
  pasos
} from "../utils.js"

import Select from 'react-select'
import '../styles/report.scss'

const ReportPostulantes = ({token}) => {
  const [ startDate, setStartDate ] = useState();
  const [ endDate, setEndDate ] = useState();
  const [ zone, setZone ] = useState();
  const [ jefeZona, setJefeZona ] = useState()
  const [ jefesZona, setJefesZona ] = useState([{value: 'todos', label: 'Todos'}])
  const [ jefeComercial, setJefeComercial ] = useState()
  const [ jefesComerciales, setJefesComerciales ] = useState([{value: 'todos', label: 'Todos'}])
  const [ users, setUsers ] = useState([])
  const [ postulations, setPostulations ] = useState([])
  
  const zones = [
    {value: 'todas', label: 'Todas'},
    {value: 'centro', label: 'Centro'},
    {value: 'norte', label: 'Norte'},
    {value: 'sur', label: 'Sur'},
    {value: 'santiago', label: 'Santiago'}
  ]

  useEffect(() => {
    if(token)
      getPostulations()
  }, [token, startDate, endDate, zone, jefeZona, jefeComercial])

  useEffect(() => {
    if(token)
      getUsers()
  }, [token])

  useEffect(() => {
    if(zone && zone.value !== 'todas' && users.length > 0){
      const jefesComercialesUsers = users.filter(u => u.zone === zone.value && u.type === 'JefeComercial')
      const newJefesComerciales = [{value: 'todos', label: 'Todos'}]

      jefesComercialesUsers.forEach(function(jefe){
        newJefesComerciales.push({
          value: jefe.id,
          label: jefe.name
        })
      })
      
      setJefesComerciales(newJefesComerciales)
      setJefeZona(null)
      setJefeComercial(null)
      setJefesZona([{value: 'todos', label: 'Todos'}])
    }
    else{
      setJefeZona()
      setJefeComercial()
      setJefesZona([{value: 'todos', label: 'Todos'}])
      setJefesComerciales([{value: 'todos', label: 'Todos'}])
    }
  }, [zone, users])

  useEffect(() => {
    if(jefeComercial && jefeComercial.value !== 'todos'){
      const jefesZonaUsers = users.filter(u => u.id_jefe_comercial === jefeComercial.value)
      const newJefesZona = [{value: 'todos', label: 'Todos'}]
      jefesZonaUsers.forEach(function(jefe){
        newJefesZona.push({
          value: jefe.id,
          label: jefe.name
        })
      })
      
      setJefesZona(newJefesZona)
      setJefeZona(null)    
    }
    else{
      setJefeZona(null)
      setJefesZona([{value: 'Todos', label: 'Todos'}])
    }
  }, [jefeComercial])

  function getPostulations(){
    displayLoader()
    let url = process.env.REACT_APP_BASE_URL + '/workflow/processes?'
    if(startDate){
      url += 'startDate=' + parseDate(startDate).substring(0, 10)
    }
    if(endDate)
      url += '&endDate=' + parseDate(endDate).substring(0, 10)

    if(jefeZona && jefeZona.value !== 'todos')
      url += '&jefeZona=' + jefeZona.value

    else if(jefeComercial && jefeComercial.value !== 'todos')
      url += '&jefeComercial=' + jefeComercial.value
    
    else if(zone && zone.value !== 'todas')
      url += '&zone=' + zone.value

    fetch(url, { headers: { token: token }})
      .then(res => res.json())
      .then((result) => {
        hideLoader()
        parsePostulations(result)
      },
      (error) => {
        alert(error);
        hideLoader()
      }
    )
  }

  function parsePostulations(result){
    const parsed = []

    result.forEach(function(postulation){
      const tmp = {
        id: postulation.id,
        fullName: postulation.name + ' ' + postulation.lastName,
        startDate: parseDate(postulation.startDate),
        endDate: parseDate(postulation.endDate),
        totalTime: getTiempoTotal(postulation),
        isFinished: postulation.stepStartDateStep17? 'Si': 'No'
      }

      for(let step = 1; step <= 17 ; step++){
        const paramName = 'timeInStep' + step.toString()
        tmp[paramName] = getTimeInStep(postulation, step)
      }

      parsed.push(tmp)
    })

    setPostulations(parsed)
  }

  function getUsers(){
    const url = process.env.REACT_APP_BASE_URL + '/workflow/users'
    fetch(url,
      { headers: { token: token }})
      .then(res => res.json())
      .then((result) => {
        setUsers(result.Items)
      },
      (error) => {
        alert(error);
      }
    )
  }

  function getTimeInStep(postulation, initialStep){
    const initialStepPropName = 'stepStartDateStep' + initialStep.toString()
    const finalStepPropName = 'stepStartDateStep' + (initialStep + 1).toString()

    const initialDate = postulation[initialStepPropName]
    const finalDate = postulation[finalStepPropName]

    let response = ''

    if(initialDate && finalDate)
      response = getDatesHoursDifference(initialDate, finalDate)
    else if(initialDate)
      response = 'En proceso desde ' + parseDate(initialDate)

    return response
  }

  function getTiemposPorPasos(postulation){
    return [...Array(17)].map(function(a, key){
      const step = key + 1
      const paramName = 'timeInStep' + step.toString()
      return(
        <td>
          {postulation[paramName]}
        </td>
      )
    })
  }

  function getPasosHeaders(){
    return pasos.map(function(paso){
      return(
        <th className="th-127">
          <div className="th-data double-line">
            <div className="th-text">Paso {paso.step}<br />{paso.label}</div>
          </div>
        </th>
      )
    })
  }

  function getTiempoTotal(postulation){
    const startDate = postulation.startDate
    let endDate = new Date()
    if(postulation.endDate)
      endDate = postulation.endDate

    return getDatesHoursDifference(startDate, endDate)
  }

  return (
    <div className="report">
      <div className="top-info">
        <div className="view-title"><b>Postulación concesionarios | </b>Reporte Postulantes</div>
        <hr className="title-hr" />
      </div>
      <div className="filters-container">
        <div className="filter-container">
          <div className="filter-title">Oficina</div>
          <div className="filter-input">
            <Select
              className='filter-select'
              value={zone}
              onChange={setZone}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              options={zones}
              components={{ SingleOption, IndicatorSeparator: () => null }}
              isClearable={false}
              isSearchable={false}
              autosize={false}
              placeholder="Seleccionar"
              styles={customStylesSelect}
            />
          </div>
        </div>

        <div className="filter-container">
          <div className="filter-title">Jefe Comercial</div>
          <div className="filter-input">
            <Select
              className='filter-select'
              value={jefeComercial}
              onChange={setJefeComercial}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              options={jefesComerciales}
              components={{ SingleOption, IndicatorSeparator: () => null }}
              isClearable={false}
              isSearchable={false}
              autosize={false}
              placeholder="Seleccionar"
              styles={customStylesSelect}
            />
          </div>
        </div>

        <div className="filter-container">
          <div className="filter-title">Jefe de Zona</div>
          <div className="filter-input">
            <Select
              className='filter-select'
              value={jefeZona}
              onChange={setJefeZona}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              options={jefesZona}
              components={{ SingleOption, IndicatorSeparator: () => null }}
              isClearable={false}
              isSearchable={false}
              autosize={false}
              placeholder="Seleccionar"
              styles={customStylesSelect}
            />
          </div>
        </div>

        <div className="filter-container">
          <div className="filter-title">Fecha Inicio</div>
          <div className="filter-input report-postulantes-datepicker">
            <DatePicker
              onChange={(date) => setStartDate(date)}
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              placeholderText="Seleccionar"
            />
          </div>
        </div>

        <div className="filter-container">
          <div className="filter-title">Fecha Fin</div>
          <div className="filter-input report-postulantes-datepicker">
            <DatePicker
              onChange={(date) => setEndDate(date)}
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              placeholderText="Seleccionar"
            />
          </div>
        </div>

        <div className="tables-container">
          <div className="table-left">
            <div className="table-title table-title-report-postulantes">
              <div className="title-text">
                POSTULANTES
              </div>
              <div className="export-excel">
                <ExportExcel
                  data={postulations}
                />
              </div>
            </div>
            <table className="copec-table-base processes-table report-postulantes-table">
              <tr>
                <th className="th-252">
                  <div className="th-data">
                    <div className="th-text">Postulante</div>
                  </div>
                </th>
                {
                  getPasosHeaders()
                }
                <th className="th-90">
                  <div className="th-data double-line">
                    <div className="th-text">Fecha<br />Inicio</div>
                  </div>
                </th>
                <th className="th-90">
                  <div className="th-data double-line">
                    <div className="th-text">Fecha<br />Termino</div>
                  </div>
                </th>
                <th className="th-110">
                  <div className="th-data double-line">
                    <div className="th-text">Tiempo<br />Total</div>
                  </div>
                </th>
                <th className="th-100">
                  <div className="th-data double-line">
                    <div className="th-text">Proceso<br />Terminado</div>
                  </div>
                </th>
              </tr>
              <tr className="table-separator"><td colSpan="5"></td></tr>
              <tbody>
                {
                  postulations.map((process) => {
                    return(
                      <tr key={process.id}>
                        <td>
                          {process.fullName}
                        </td>
                        {getTiemposPorPasos(process)}
                        <td>
                          {process.startDate}
                        </td>
                        <td>
                          {process.endDate}
                        </td>
                        <td>
                          {process.totalTime}
                        </td>
                        <td>
                          {process.isFinished}
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ReportPostulantes;