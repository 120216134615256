import React, { Fragment, useState, useEffect } from "react";
import history from "../history"
import { displayLoader, hideLoader } from "../utils.js"
import { dictionary } from '../dictionary.js'
import '../styles/applicant.scss'
import '../styles/rejection.scss'

const Rejection = (props) => {
  const [ token, setToken ] = useState(null)
  const [ rejectionComment, setRejectionComment ] = useState('')
  const [ email, setEmail ] = useState('')
  const [ enableSubmitButton, setEnableSubmitButton ] = useState(false)
  const id = props.match.params.id

  useEffect(() => {
    if(props.token){
      setToken(props.token)
      getRejectionEmail(props.token)
    }
  }, [props.token]);

  async function getRejectionEmail(token){
    displayLoader()
    const url = process.env.REACT_APP_BASE_URL + '/workflow/rejection?id=' + id
    await fetch(url, { headers: { token: token }})
      .then(res => res.json())
      .then((result) => {
        setEmail(result)
        setEnableSubmitButton(true)
      },
      (error) => {
        alert(error);
      }
    )

    hideLoader()
  }

  async function submitRejection(){
    displayLoader()
    const data = {
      id: id,
      rejectionComment: rejectionComment
    }

    const url = process.env.REACT_APP_BASE_URL + '/workflow/rejection'

    await fetch(url, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(
        (result) => {
          history.push('/postulaciones')
        },
        (error) => {
          alert(error)
        }
      )

    hideLoader()
  }

  function cancel(){
    history.push('/postulante/' + id)
  }

  return (
    <div className='applicant'>
      <h1>Rechazo de postulante</h1>
      <h2>Comentario (Opcional)</h2>
      <textarea className="text-input" value={rejectionComment} onChange={(e) => setRejectionComment(e.target.value)} rows="4" />

      <h2>Se enviará el siguiente mail al postulante</h2>
      <div className="rejection-email" dangerouslySetInnerHTML={{__html: email }} />

      <button className='save-changes' onClick={submitRejection} disabled={!enableSubmitButton}>Aceptar</button>
      <button className='save-changes' onClick={cancel} disabled={!enableSubmitButton}>Cancelar</button>
    </div>
  )
};

export default Rejection;
