import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker"

import { 
  displayLoader,
  hideLoader,
  parseDate,
  parentRedirect,
  customStylesSelect,
  SingleOption,
  orderArray
} from "../utils.js";

import { etapasDictionary } from '../dictionary.js'
import Select from 'react-select'
import '../styles/report.scss';
import userIcon from '../assets/img/user.svg'
import approvedIcon from '../assets/img/approved.svg'
import processIcon from '../assets/img/process.svg'
import rejectedIcon from '../assets/img/rejected.svg'
import Flecha_down from "../assets/img/caret_down.svg";
import Flecha_up from "../assets/img/caret_up.svg";

const Report = (props) => {
  const date = new Date();
  date.setFullYear( date.getFullYear() - 1 );
  const [ startDate, setStartDate ] = useState(date);
  const [ endDate, setEndDate ] = useState(new Date());
  const [ zone, setZone ] = useState();
  const [ status, setStatus ] = useState('todas');
  const [ etapa, setEtapa ] = useState('todas');
  const [ detailData, setDetailData ] = useState([]);
  const [ token, setToken ] = useState(null);
  const [ information, setInformation ] = useState([]);
  const [ totalActive, setTotalActive ] = useState(0);
  const [ totalRejected, setTotalRejected ] = useState(0);
  const [ totalFinished, setTotalFinished ] = useState(0);
  const [ selected, setSelected ] = useState({})
  const [ filteredInformation, setFilteredInformation ] = useState([])
  const [ jefesZona, setJefesZona ] = useState([])
  const [ jefesComerciales, setJefesComerciales ] = useState([{value: 'Todos', label: 'Todos'}])
  const [ jefeComercial, setJefeComercial ] = useState()
  const [ jefesComercialesSet, setJefesComercialesSet ] = useState(false)
  
  const zones = [
    {value: 'todas', label: 'Todas'},
    {value: 'centro', label: 'Centro'},
    {value: 'norte', label: 'Norte'},
    {value: 'sur', label: 'Sur'},
    {value: 'santiago', label: 'Santiago'}
  ]

  useEffect(() => {
    if(props.token) {
      setToken(props.token);
      getZones(props.token);
    }
  }, [props.token]);

  useEffect(() => {
    if(jefeComercial === 'Todos'){
      setFilteredInformation([...information])
      return
    }

    const filtered = []

    information.forEach(function(info){
      if(info.jefe_comercial === jefeComercial.value)
        filtered.push(info)      
    })

    setFilteredInformation(filtered)
  }, [jefeComercial]);

  useEffect(() => {
    // change search each time you look for something
    setDetailData([])
    if(token) {
      displayLoader()
      
      fetch(process.env.REACT_APP_BASE_URL + `/workflow/reports/end_processes${getQueryParams()}`, {
        headers: {
          token: token,
        },
      })
        .then(res => res.json())
        .then(res => {
          hideLoader()
          setSelected({})
          let newTotalActive = 0, newTotalRejected = 0, newTotalFinished = 0
          const newInformation = []
          res.forEach(function(p){
            newTotalActive += p.activeProcesses
            newTotalRejected += p.rejectedProcesses
            newTotalFinished += p.finishedProcesses
          })
          setTotalActive(newTotalActive)
          setTotalRejected(newTotalRejected)
          setTotalFinished(newTotalFinished)

          setInformation(res)
          setFilteredInformation(res)
        });
    }
  }, [token, startDate, endDate, zone, status, etapa]);

  useEffect(() => {
    if(information.length > 0 && jefesZona.length > 0 && !jefesComercialesSet){
      const jefes = [{value: 'Todos', label: 'Todos'}]
      const newInformation = []

      information.forEach(function(pos){
        const newJefeZona = jefesZona.find(u => u.id === pos.id)
        if(newJefeZona){
          pos.name = newJefeZona.name
          newInformation.push(pos)
          const newJefeComercial = jefesZona.find(u => u.id === newJefeZona.id_jefe_comercial)
          if(newJefeComercial){
            if(!jefes.find(e => newJefeComercial.id === e.value ))
              jefes.push({value: newJefeComercial.id, label: newJefeComercial.name})
          }
        }
      })
      setInformation(newInformation)
      setJefesComercialesSet(true)
      setJefesComerciales(jefes)
    }
  }, [information, jefesZona])

  const handleApplicantClick = (id) => {
    const url = "/c-postulante/" + id;
    parentRedirect(url)
  }

  function getQueryParams(){
    const params = [];
    if(startDate) params.push(`startDate=${startDate.valueOf()}`);
    if(endDate) params.push(`endDate=${endDate.valueOf()}`);
    if(zone && zone.value !== 'todas') params.push(`zone=${zone.value}`);
    if(status !== 'todas') params.push('status=' + status)
    if(etapa !== 'todas') params.push('etapa=' + etapa)
    const queryParams = params.length? '?'.concat(params.join(';')):'';
    return queryParams
  }

  function getZones(newToken){
    const url = process.env.REACT_APP_BASE_URL + '/workflow/users'
    fetch(url,
      { headers: { token: newToken }}
    ).then(res => res.json())
    .then((result) => {
      setJefesZona(result.Items)
      },
      (error) => {
        alert(error);
      }
    )
  }

  function getCard(title, subtitle, icon, total, classes){
    return <div className={`report-card ${classes}`}>
        <div className="left-side">
          <img src={icon} />
        </div>
        <div className="right-side">
          <div className="top">
            <b>{title} &nbsp;&nbsp;&nbsp;</b>
            <span>{subtitle}</span>
          </div>
          <div className="bottom">
            <b>{total}</b>
          </div>
        </div>
      </div>
  }

  const orderTable = (filterkey, order, parse) => {
    const arr = orderArray(filterkey, filteredInformation, order, parse)
    setFilteredInformation(arr)
  }

  function getJefeZona(id, status, subtotal, nombre){
    if(subtotal === 0)
      return

    setSelected({'id': id, 'status': status, 'nombre': nombre});
    let queryParams = getQueryParams()
    queryParams = queryParams.replace('?', ';')

    displayLoader()

    fetch(process.env.REACT_APP_BASE_URL + `/workflow/reports/processes_details?id=${id};status=${status}${queryParams}`, {
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
    })
      .then(res => res.json())
      .then(res => {
        hideLoader()
        setDetailData(res.map(applicant => {
          const diffTime = Math.abs(new Date(applicant.endDate) - new Date(applicant.startDate));
          applicant.days = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
          applicant.date = parseDate(applicant.endDate);
          applicant.wasAccepted = (applicant.FechaEntregaEds? 'Sí' : 'No')
          return applicant;
        }));
      });
  }

  function getStatusName(){
    if(selected.status === 'activas')
      return 'en proceso'

    if(selected.status === 'terminadas')
      return 'terminados'

    if(selected.status === 'rechazadas')
      return 'rechazados'
  }

  function getTiempoEnProceso(startDate, endDate){
    if(!endDate)
      endDate = new Date()
    else 
      endDate = new Date(endDate)
    
    startDate = new Date(startDate)

    const days = Math.round((endDate-startDate)/(1000*60*60*24));
    const weeks = Math.floor(days/7)
    const remainingDays = days%7

    let str = ''
    if(weeks > 0)
      str += weeks.toString() + ' semanas, '

    str += remainingDays.toString() + ' días'

    return str
  }

  return (
    <div className="report">
      <div className="top-info">
        <div className="view-title"><b>Postulación concesionarios | </b>Reportes</div>
        <hr className="title-hr" />
      </div>
      <div className="cards-container">
        { getCard('Total Postulantes', '', userIcon, totalActive + totalRejected + totalFinished, 'blue') }
        { getCard('Postulantes', 'En proceso', processIcon, totalActive, 'yellow') }
        { getCard('Postulantes', 'Terminados', approvedIcon, totalFinished, 'green') }
        { getCard('Postulantes', 'Rechazados', rejectedIcon, totalRejected, 'red last') }
      </div>

      <hr />
      <div className="filters-container">
        <div className="filter-container">
          <div className="filter-title">Zona</div>
          <div className="filter-input">
            <Select
              className='filter-select'
              value={zone}
              onChange={setZone}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              options={zones}
              components={{ SingleOption, IndicatorSeparator: () => null }}
              isClearable={false}
              isSearchable={false}
              autosize={false}
              placeholder="Seleccionar"
              styles={customStylesSelect}
            />
          </div>
        </div>

        <div className="filter-container">
          <div className="filter-title">Jefe Comercial</div>
          <div className="filter-input">
            <Select
              className='filter-select'
              value={jefeComercial}
              onChange={setJefeComercial}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              options={jefesComerciales}
              components={{ SingleOption, IndicatorSeparator: () => null }}
              isClearable={false}
              isSearchable={false}
              autosize={false}
              placeholder="Seleccionar"
              styles={customStylesSelect}
            />
          </div>
        </div>
      </div>

      <div className="tables-container">
        <div className="table-left">
          <div className="table-title">
            JEFES DE ZONA
          </div>
          <table className="copec-table-base processes-table">
            <tr>
              <th className="first th-100">
                <div className="th-data"><div className="th-text">Zona
                  <div className="arrows">
                    <img
                      className="arrow-up"
                      onClick={() => orderTable("zone", "asc")}
                      src={Flecha_up}
                    />
                    <img
                      className="arrow-down"
                      onClick={() => orderTable("zone", "desc")}
                      src={Flecha_down}
                    />
                  </div>
                </div></div>
              </th>
              <th className="th-252">
                <div className="th-data"><div className="th-text">Jefe de Zona
                  <div className="arrows">
                    <img
                      className="arrow-up"
                      onClick={() => orderTable("name", "asc")}
                      src={Flecha_up}
                    />
                    <img
                      className="arrow-down"
                      onClick={() => orderTable("name", "desc")}
                      src={Flecha_down}
                    />
                  </div>
                  </div></div>
              </th>
              <th className="th-120">
                <div className="th-data"><div className="th-text">En proceso
                  <div className="arrows">
                    <img
                      className="arrow-up"
                      onClick={() => orderTable("activeProcesses", "asc")}
                      src={Flecha_up}
                    />
                    <img
                      className="arrow-down"
                      onClick={() => orderTable("activeProcesses", "desc")}
                      src={Flecha_down}
                    />
                  </div>
                </div></div>
              </th>
              <th className="th-120">
                <div className="th-data"><div className="th-text">Terminados
                  <div className="arrows">
                    <img
                      className="arrow-up"
                      onClick={() => orderTable("finishedProcesses", "asc")}
                      src={Flecha_up}
                    />
                    <img
                      className="arrow-down"
                      onClick={() => orderTable("finishedProcesses", "desc")}
                      src={Flecha_down}
                    />
                  </div>
                </div></div>
              </th>
              <th className="last th-120">
                <div className="th-data"><div className="th-text">Rechazados
                  <div className="arrows">
                    <img
                      className="arrow-up"
                      onClick={() => orderTable("rejectedProcesseseje", "asc")}
                      src={Flecha_up}
                    />
                    <img
                      className="arrow-down"
                      onClick={() => orderTable("rejectedProcesseseje", "desc")}
                      src={Flecha_down}
                    />
                  </div>
                </div></div>
              </th>
            </tr>
            <tr className="table-separator"><td colSpan="5"></td></tr>
            <tbody>
              {
                filteredInformation.map((process) => {
                  return(
                    <tr key={process.id}>
                      <td>
                        {process.zone}
                      </td>
                      <td>
                        {process.name}
                      </td>
                      <td onClick={() => getJefeZona(process.id, 'activas', process.activeProcesses, process.name)}>
                        <div 
                          className={`${process.activeProcesses > 0? 'report-link': 'report-no-link'} ${selected.id === process.id && selected.status === 'activas'? 'active': ''}`}>
                          {process.activeProcesses}
                        </div>
                      </td>
                      <td onClick={() => getJefeZona(process.id, 'terminadas', process.finishedProcesses, process.name)}>
                        <div className={`${process.finishedProcesses > 0? 'report-link': 'report-no-link'} ${selected.id === process.id && selected.status === 'terminadas'? 'active': ''}`}>
                          {process.finishedProcesses}
                        </div>
                      </td>
                      <td onClick={() => getJefeZona(process.id, 'rechazadas', process.rejectedProcesses, process.name)}>
                        <div className={`${process.rejectedProcesses > 0? 'report-link': 'report-no-link'} ${selected.id === process.id && selected.status === 'rechazadas'? 'active': ''}`}>
                          {process.rejectedProcesses}
                        </div>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>

        {detailData.length > 0 &&
          <div className="table-right">
            <div className="table-title">
              POSTULACIONES
            </div>
            
            <table className="tabla-copec-1">
              <tr>
                <th colSpan="5">Postulaciones de: {selected.nombre}, {getStatusName()}</th>
              </tr>
              {detailData.map((row) => (
                <>
                  <tr className="table-row-separator"><td colSpan="5"></td></tr>
                  <tr className="table-row" onClick={() => handleApplicantClick(row.id)}>
                    <td className="first-column"><b>{row.name}</b><br />{row.Eds? `EDS ${row.Eds}`: '-'}</td>
                    <td>Paso<br />{row.step}</td>
                    <td>Fecha Inicio<br />{parseDate(row.startDate)}</td>
                    <td>Fecha Fin<br />{row.endDate? parseDate(row.endDate): '-'}</td>
                    <td className="last-column">Tiempo en proceso<br />{getTiempoEnProceso(row.startDate, row.endDate)}</td>
                  </tr>
                </>
              ))}
            </table>
          </div>
        }
      </div>
      <div className="bottom-space" />
    </div>
  )
};

export default Report;