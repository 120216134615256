export const dictionary = {
	//Nombres de Pasos
	InitialRevision: "Revisión inicial de solicitudes",
	EntrevistaJefeZona: "Entrevista Jefe de Zona",
	EntrevistaPsicologica: "Entrevista Psicológica",
	EntrevistaJefeComercial: "Entrevista Jefe Comercial",
	EntrevistaGerenteOficina: "Entrevista Gerente de Oficina",
	CursoInduccion: "Curso de Inducción",
	RevisionInformeCopecCentro: "Revision Informe Curso Inducción",
	PracticaEds: "Práctica EDS",
	PruebaCertificacion: "Prueba de Certificación",
	RevisionDocumentacion: "Revisión Documentación",
	FirmaCartasCompromiso: "Firma Cartas de Compromiso",
	RevisionNombramiento: "Revisión Nombramiento",
	FirmaNombramientoGerenteOficinaComercial: "Firma Nombramiento Gerente Oficina Comercial",
	FirmaNombramientoGerenteEds: "Firma Nombramiento Gerente EDS",
	FirmaNombramientoGerenteComercial: "Firma Nombramiento Gerente Comercial",
	CierreNombramiento: "Cierre Nombramiento",
	EntregaEds: "Toma de EDS",

	//Nombres de inputs
	Next: "Siguiente",
	End: "Terminar",
	Rechazo: "Rechazo",
	InformePsicologico: "Informe Psicológico",
	FechaPsicologico: "Fecha",
	NotaPsicologico: "Nota Entrevista Psicológica",
	FechaJefeZona: "Fecha",
	NotaJefeZona: "Nota",
	InformeJefeZona: "Informe de jefe de zona",
	RecomendacionPsicologico: "Recomendación",
	InformeJefeComercial: "Informe Jefe Comercial",
	FechaJefeComercial: "Fecha",
	FechaGerenteOficina: "Fecha",
	expectedIncome: "Ingresos Esperados",

	NotaJefeComercial: "Nota",
	NotaGerenteOficina: "Nota",
	ComentarioJefeZona: "Comentarios",
	ComentarioJefeComercial: "Comentarios",
	ComentarioGerenteOficina: "Comentarios",

	CorreoCursoInduccion: "Correo enviado con acceso a material de prueba de certificación",
	FechaCursoInduccion: "Fecha Inicio",
	FechaFinCursoInduccion: "Fecha Fin",
	InformeCursoInduccion: "Informe curso",
	CartaRecomendacionCursoInduccion: "Carta de recomendación",
	NotaCursoInduccion: "Nota",

	EdsPractica: "EDS",
	FechaPractica: "Fecha Inicio",
	FechaFinPractica: "Fecha Fin",
	InformePractica: "Informe",
	NotaPractica: "Nota",
	TutorPractica: "Nombre Tutor",

	FechaPrueba: "Fecha",
	NotaPrueba: "Nota",
	CertificadoPrueba: "Certificado",

	FechaRevisionInformeCopecCentro: "Fecha Revisión",
	InformeCopecCentroRevisado: "Informe revisado",

	CuadroVentasCombustiblesLubricantes: "Completa el cuadro de compromiso de ventas de combustibles (m³)",
	MesInicioOperacion: "Mes de inicio",
	CuadroCapitalTrabajo: "Completa el cuadro de capital de trabajo (UF)",
	DireccionEds: "Dirección EDS",
	Eds: "EDS",
	PromedioMensualLubricantes: "Promedio mensual Lubricantes Mobil (LTS)",
	RazonSocial: "Razón social",

	NacionalidadConcesionario: "Nacionalidad Postulante",
	ConcesionarioSaliente: "Nombre concesionario saliente",
	RutSociedad: "Rut Sociedad",
	TableSocios: "Integrantes de la Sociedad",
	PromedioVentasMesDesde: "Mes inicio promedio ventas ultimos 3 meses",
	PromedioVentasKerosene: "Promedio ventas Kerosene",
	PromedioVentasGasolina: "Promedio ventas Gasolina",
	PromedioVentasDiesel: "Promedio Ventas Diesel",
	PromedioVentasLubricantes: "Promedio Ventas Lubricantes",
	CapitalExplotacion: "Capital explotación",
	EquivalenteEnDiasVenta: "Equivalente en días de venta",
	CapitalInversion: "Capital para inversión",
	BienRaiz: "Descripción del bien raíz",
	DireccionBienRaiz: "Dirección bien raiz",
	CiudadBienRaiz: "Ciudad bien raiz",
	ValorBienRaiz: "Valor comercial (UF) bien raiz",
	ValorLiquidacionBienRaiz: "Valor de liquidación bien raiz",
	DireccionOtroBienRaiz: "Dirección otras garantías",
	CiudadOtroBienRaiz: "Ciudad otras garantías",
	ValorOtroBienRaiz: "Valor comercial (UF) otras garantías",
	ValorLiquidacionOtroBienRaiz: "Valor de liquidación otras garantías",

	//Documentos transversales
	//Legal - Sociedad
	EscrituraSociedad: "Borrador Escritura Sociedad",
	ConstitucionSociedad: "Constitución sociedad aprobada por asesoría legal",

	//Legal - Hipoteca
	HipotecaFavorCopec: "BORRADOR hipoteca a favor de copec",
	TasacionPropiedadHipoteca: "Tasación de la propiedad hipotecada",
	CopiaDominioVigente: "Inscripción de dominio con certificado de vigencia",
	EscrituraCompraPropiedad: "Escritura compraventa",
	EscrituraCompraPropiedadAnteriores: "Escrituras publicas de compraventa/títulos traslaticios (hasta 10 años)",
	CertificadoContribuciones: "Certificado deuda contribuciones o avaluo para bienes excentos de contribuciones",
	Serviu: "Certificado no expropiación serviu",
	DireccionObrasMunicipales: "Certificado no expropiabilidad dirección de obras municipales",
	CertificadoHipotecaGravamenes: "Certificado de hipotecas y gravámenes",
	CertificadoMatrimonio: "Certificado de matrimonio",
	InscripcionSociedadRegistroComercio: "Inscripción sociedad registro de comercio",
	PoderesRepresentantesLegalesVigente: "Poderes de los representantes legales con vigencia",

	FechaEntregaEds: "Fecha",

	//Memos
	MemoLegal: "Memo Area Legal",
	MemoFinanzas: "Memo aprobación finanzas",
	MemoSociedad: "Memo constitución sociedad sin reparos",
	MemoHipoteca: "Memo hipoteca formalizada",

	DocFinanzas: "Informe comercial",
	DocSociedad: "Constitución sociedad aprobada por As. Legal",
	DocHipoteca: "Constitución hipoteca",

	Archivo1: "Archivo obligatorio 1",
	Archivo2: "Archivo obligatorio 2",
	Archivo3: "Archivo obligatorio 3",

	// Tipos de rechazo
	no_vacancy: "No hay vacantes",
	does_not_meet_requirements: "No cumple requisitos",
	another_reason: "Otra razon"
}

export const errorsDictionary = {
	'workflow creation': 'No se creo correctamente el workflow. ',
	'uploadDocuments': 'Error al subir los documentos. ',
	'setSignableDocuments': 'Error al asignar los documentos para firma. ',
	'assignSigner': 'Error al asignar firmantes. ',
	'validateStep': 'Error al validar paso. ',
	'assignToSteps': 'Error al asignar usuario al paso. ',
	'carton': 'Carton de Nombramiento',
	'compromises': 'Cartas de compromiso',
	'fastSign': 'Firma Rapida',
	'hipoteca': 'Hipoteca',
	'sociedad': 'Sociedad'
}

export const etapasDictionary = {
	'Sel': 'Selección',
	'Cap': 'Centro de evaluación',
	'Nom': 'Nombramiento',
	'Toma': 'Toma de la EDS'
}

