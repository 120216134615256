import React, { useEffect, useCallback, Fragment } from "react";
import { components } from "react-select"
import file_pdf_on from "./assets/img/file_pdf_on.svg"
import file_ppt_on from "./assets/img/file_ppt_on.svg"
import file_xls_on from "./assets/img/file_xls_on.svg"
import file_doc_on from "./assets/img/file_doc_on.svg"
import file_generic_on from "./assets/img/file_generic_on.svg"

export const sociedadFiles = [ 
  "EscrituraSociedad",
  "ConstitucionSociedad"
]

export const hipotecaFiles = [
  {
    key: "HipotecaFavorCopec",
    required: true
  },
  {
    key: "TasacionPropiedadHipoteca",
    required: true
  },
  {
    key: "CopiaDominioVigente",
    required: true
  },
  {
    key: "EscrituraCompraPropiedad",
    required: true
  },
  {
    key: "EscrituraCompraPropiedadAnteriores",
    required: true
  },
  {
    key: "CertificadoContribuciones",
    required: true
  },
  {
    key: "Serviu",
    required: true
  },
  {
    key: "DireccionObrasMunicipales",
    required: true
  },
  {
    key: "CertificadoHipotecaGravamenes",
    required: true
  },
  {
    key: "CertificadoMatrimonio",
    required: false
  },
  {
    key: "InscripcionSociedadRegistroComercio",
    required: false
  },
  {
    key: "PoderesRepresentantesLegalesVigente",
    required: false
  }
]

export const pasos = [
  {
    step: 1,
    stepTimeLimit: 2,
    stepName: "InitialRevision",
    label: 'Revisión Inicial'
  },
  {
    step: 2,
    stepTimeLimit: 2,
    stepName: "EntrevistaJefeZona",
    label: 'E. Jefe Zona'
  },
  {
    step: 3,
    stepTimeLimit: 2,
    stepName: "EntrevistaPsicologica",
    label: 'E. Psicológica'
  },
  {
    step: 4,
    stepTimeLimit: 2,
    stepName: "EntrevistaJefeComercial",
    label: 'E. Jefe Comercial'
  },
  {
    step: 5,
    stepTimeLimit: 2,
    stepName: "EntrevistaGerenteOficina",
    label: 'E. Grte Oficina'
  },
  {
    step: 6,
    stepTimeLimit: 2,
    stepName: "CursoInduccion",
    label: 'Curso Inducción'
  },
  {
    step: 7,
    stepTimeLimit: 2,
    stepName: "RevisionInformeCopecCentro",
    label: 'Rev Info Copec Centro'
  },
  {
    step: 8,
    stepTimeLimit: 2,
    stepName: "PruebaCertificacion",
    label: 'Prueba Certificación'
  },
  {
    step: 9,
    stepTimeLimit: 2,
    stepName: "RevisionDocumentacion",
    label: 'Rev Documentación'
  },
  {
    step: 10,
    stepTimeLimit: 2,
    stepName: "FirmaCartasCompromiso",
    label: 'F. Cartas Compromiso'
  },
  {
    step: 11,
    stepTimeLimit: 2,
    stepName: "RevisionNombramiento",
    label: 'Rev Nombramiento'
  },
  {
    step: 12,
    stepTimeLimit: 2,
    stepName: "FirmaNombramientoGerenteOficinaComercial",
    label: 'F. Grte Of Comercial'
  },
  {
    step: 13,
    stepTimeLimit: 2,
    stepName: "FirmaNombramientoGerenteEds",
    label: 'F. Grte EDS'
  },
  {
    step: 14,
    stepTimeLimit: 2,
    stepName: "FirmaNombramientoGerenteComercial",
    label: 'F. Grte Comercial'
  },
  {
    step: 15,
    stepTimeLimit: 2,
    stepName: "CierreNombramiento",
    label: 'Cierre Nombramiento'
  },
  {
    step: 16,
    stepTimeLimit: 2,
    stepName: "PracticaEds",
    label: 'Práctica EDS'
  },
  {
    step: 17,
    stepTimeLimit: 2,
    stepName: "EntregaEds",
    label: 'Entrega EDS'
  }
]

export const cartasCompromiso = [
  "capital_de_trabajo",
  "compromiso_vtas",
  "dedicacion_exclusiva",
  "derecho_de_llave",
  "imagen_y_servicio",
  "mps",
  "plataformas_web",
  "precios_competitivos",
  "seguro_y_retiro_de_valores"
]

export const months = [ 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ]

export const customStylesSelect = {
  control: (base, state) => ({
    ...base,
    boxShadow: "none",
    height: 32,
    border: "1px solid #7c97ab",
    background: state.isDisabled ? "#f1f5f8" : "white",
    width: state.selectProps.width,
    color: "green"
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "none",
    color: "#003965",
    background: "none",
    padding: "12px 15px",
    fontSize: "12px",
    fontWeight: "500",
    "&:hover": {
      background: "#f1f5f8"
    }
  }),
  menu: (provided, state) => ({
    ...provided,
    marginTop: "-2px",
    border: "1px solid #809cb2",
    boxShadow: "none",
    boxSizing: "content-box",
    width: "calc(100% - 2px)",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    maxMenuHeight: "100px"
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#003965",
    fontWeight: "normal",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    background: "white",
    fontSize: "12px",
    fontWeight: "600",
    color: "#003965",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    fontWeight: "600",
    color: "#003965",
  }),
  singleValueLabel: (provided, state) => ({
    ...provided,
    background: "white",
    fontSize: "12px",
    fontWeight: "600",
    color: "#003965",
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    background: "white",
    display: "none",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#7c97ab",
    fontWeight: "600",
    fontSize: "12px"
  })
};

export const SingleOption = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <div className="single-option-label">{props.label}</div>
      </components.Option>
    </div>
  );
};

export function displayLoader() {
  window.parent.postMessage({ showLoader: true }, "*");
}

export function hideLoader() {
  window.parent.postMessage({ hideLoader: true }, "*");
}

export function parentRedirect(url){
  window.parent.postMessage({'redirect': url}, "*");
}

export function showGreyBackground() {
  window.parent.postMessage({ showGreyBackground: true }, "*");
}

export function hideGreyBackground() {
  window.parent.postMessage({ hideGreyBackground: true }, "*");
}

export function scrollTo(x, y) {
  window.parent.postMessage({ scrollTo: { x: x, y: y } }, "*");
}

export async function getPresignedPostData(fileName, fileExtension, fileType, id, token, isMultiple) {
  const data = {
    fileName: fileName,
    fileExtension: fileExtension,
    fileType: fileType,
    id: id,
    isMultiple: isMultiple
  }

  return await fetch(process.env.REACT_APP_BASE_URL + "/workflow/presigned_url", {
    method: "POST",
    headers: {
      token: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data)
  })
  	.then(res => res.json())
    .then(
      (result) => {
        return result
      },
      (error) => {
        alert(error)
        return false
      }
    )
};

export async function uploadFile(file, fileName, id, token, isMultiple) {
  const fileExtension = file.name.split('.').pop()
  const fileType = file.type

	const presignedPostData = await getPresignedPostData(fileName, fileExtension, fileType, id, token, isMultiple)
  if (presignedPostData) {
  	await putFile(presignedPostData[0], file)
  }
}

export async function deleteMultipleFilesItemUploaded(key, index, id, token){
  const data = {
    fileName: key,
    index: index,
    id: id
  }

  return await fetch(process.env.REACT_APP_BASE_URL + "/workflow/delete_multiple_files_item", {
    method: "POST",
    headers: {
      token: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(
      (result) => {
        return result
      },
      (error) => {
        alert(error)
        return false
      }
    )
}

export async function putFile(url, file){
  const fileBlob = new Blob([file], { type: file.type })
  
  await fetch(url, { 
    method: 'PUT',
    body: fileBlob
  })
  .then((result) => {},
    (error) => {
      alert(error);
    }
  )
}

export async function getPresignedUrlForGet(fileName, token) {
  let response

  await fetch(process.env.REACT_APP_BASE_URL + "/workflow/presigned_url?file_name=" + fileName, {
    headers: {
      token: token
    }
  })
  	.then(res => res.json())
    .then(
      (result) => {
        response = result
      },
      (error) => {
        alert(error)
        response = false
      }
    )
  return response
}

export function dynamicSort(property) {
  var sortOrder = 1;
  if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
  }
  return function (a,b) {
      /* next line works with strings and numbers, 
       * and you may want to customize it to your needs
       */
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
  }
}

export function parseDate(date){
  if(!date){
    return ''
  }

  date = new Date(date)

  let dd = date.getDate()
  let mm = date.getMonth() + 1
  const yyyy = date.getFullYear()

  let hours = date.getHours()
  let minutes = date.getMinutes()

  if(dd < 10){
    dd = '0' + dd.toString()
  }

  if(mm < 10){
    mm = '0' + mm.toString()
  }

  if(hours < 10){
    hours = '0' + hours.toString()
  }

  if(minutes < 10){
    minutes = '0' + minutes.toString()
  }

  const parsedDate = dd.toString() + '/' + mm.toString() + '/' + yyyy.toString() + ' ' + hours.toString() + ':' + minutes.toString()
  return parsedDate
}

export function getDatesHoursDifference(startDate, endDate){
  const start = new Date(startDate)
  const end = new Date(endDate)

  const diff = end - start

  let seconds = Math.floor(diff/1000)
  let minutes = Math.floor(seconds/60)
  let hours = Math.floor(minutes/60)
  let days = Math.floor(hours/24)

  hours = hours - (days * 24)
  minutes = minutes - (days * 24 * 60) - (hours * 60)

  if(days === 0){
    days = ''
  }
  else if(days === 1){
    days = '1 día con '
  }
  else{
    days = days.toString() + ' días con '
  }

  if(hours === 0){
    hours = ''
  }

  else if(hours === 1){
    hours = '1 hora y '
  }
  else{
    hours = hours.toString() + ' horas y '
  }

  if(minutes === 1){
    minutes = '1 minuto'
  }
  else{
    minutes = minutes.toString() + ' minutos'
  }

  return days + hours + minutes
}

export function unparseDate(date){
  if(!date)
    return null

  const dateArr = date.split('/')
  return new Date(dateArr[2].substring(0, 4), (parseInt(dateArr[1]) - 1).toString(), dateArr[0])
}

export function parseTime(date){
  if(!date){
    return ''
  }

  date = new Date(date)

  let hh = date.getHours()
  let mm = date.getMinutes()

  if(hh < 10){
    hh = '0' + hh.toString()
  }

  if(mm < 10){
    mm = '0' + mm.toString()
  }

  const parsedTime = hh.toString() + ':' + mm.toString()
  return parsedTime
}


export function getRemainingTime(stepStartDate, stepTimeLimit){
  if(!stepStartDate || !stepTimeLimit) { return '' }

  let endDate = new Date(stepStartDate);
  endDate.setDate(endDate.getDate() + stepTimeLimit)

  let remaining = endDate - Date.now()
  
  const days = Math.floor(remaining / (24 * 60 * 60000))
  remaining = remaining - (days * (24 * 60 * 60000))

  const hours = Math.floor(remaining / (60 * 60000))
  remaining = remaining - (hours * (60 * 60000))

  //const minutes = Math.floor(remaining / 60000);

  //return days.toString() + "d " + hours.toString() + "hr " + minutes.toString() + "min"
  return days.toString() + "d " + hours.toString() + "hr"
};

export function isParallelStepStarting(date){
  if(!date) { return false }

  let stepDate = new Date(date);
  let timeSince = Date.now() - stepDate

  const minutes = Math.floor(timeSince / 60000);

  if(minutes > 5)
    return false

  return true
};

export function validateRut(rut){
  if(rut.length < 5){
    return false;
  }

  if(rut[rut.length - 2] !== '-'){
    return false;
  }

  let valor = rut.replace(/\./g, '');
  valor = valor.replace(/-/g, '');
  
  // Aislar Cuerpo y Dígito Verificador
  const cuerpo = valor.slice(0,-1);
  let dv = valor.slice(-1).toUpperCase();
  // Si no cumple con el mínimo ej. (n.nnn.nnn)
  if(cuerpo.length < 7) { return false;}
  // Calcular Dígito Verificador
  let suma = 0;
  let multiplo = 2;
  // Para cada dígito del Cuerpo
  for(let i = 1; i <= cuerpo.length; i++) {
      const index = multiplo * valor.charAt(cuerpo.length - i);
      suma = suma + index;
      if(multiplo < 7) { multiplo = multiplo + 1; } else { multiplo = 2; }
  }
  
  // Calcular Dígito Verificador en base al Módulo 11
  const dvEsperado = 11 - (suma % 11);
  
  // Casos Especiales (0 y K)
  dv = (dv == 'K')?10:dv;
  dv = (dv == 0)?11:dv;

  // Validar que el Cuerpo coincide con su Dígito Verificador
  if(dvEsperado != dv) { return false; }
  // Si todo sale bien, eliminar errores (decretar que es válido)
  return true
}

export function validateEmail(email) 
{
  var re = /\S+@\S+\.\S+/
  return re.test(email)
}

export function getTotalTime(startDate){
  const today = new Date()
  const diff = Math.ceil((today - startDate) / (1000 * 60 * 60 * 24))

  return diff
}

export function getLocaleStringNum(num){
  if(!num)
    return ''
  
  let parsed = parseInt(num).toLocaleString('en')
  parsed = parsed.replace(/,/g, '.')
  return parsed
}

export const orderArray = (filterkey,arr,order,parse) => {
  if(filterkey === 'jefe_comercial' || filterkey === 'jefeZona'){
    const jefesArr = []
    const noJefesArr = []
    arr.forEach(function(j){
      if(j['jefeZona']){
        jefesArr.push(j)
      }
      else{
        noJefesArr.push(j)
      }
    })

    const realKey = filterkey === 'jefe_comercial'? 'jefe_comercial': 'name'

    if(order==='asc'){
      const final = jefesArr.sort((a,b)=> a['jefeZona'][realKey] > b['jefeZona'][realKey]? 1:-1)
      return final.concat(noJefesArr)
    }
    else{
      const final = jefesArr.sort((a,b)=> a['jefeZona'][realKey] > b['jefeZona'][realKey]? -1:1)
      return noJefesArr.concat(final)
    }
  }

  const arrfinal=[...arr]
  if(order==='asc'){
    return arrfinal.sort((a,b)=>a[filterkey] > b[filterkey]? 1:-1)
  }
  else{
    return arrfinal.sort((a,b)=>a[filterkey] > b[filterkey]? -1:1)
  }
}

export const getFileExtension = (filename) => {
  const extension = filename.split('.').pop()
  return extension
}

export async function checkWorkflowStatus(token){
  displayLoader()
  
  await fetch(process.env.REACT_APP_BASE_URL + "/workflow/check_workflows", {
    headers: { token: token }
  })

  hideLoader()
}

export const getFileTypeIcon = (extension) => {
  if(extension === 'pdf')
    return file_pdf_on

  else if(extension === 'ppt' || extension === 'pptx')
    return file_ppt_on

  else if(extension === 'xls' || extension === "xlsx")
    return file_xls_on

  else if(extension === 'doc' || extension === 'docx')
    return file_doc_on

  else
    return file_generic_on
}

export const setHistorialData = (historial, setStartProcessDate, setTotalProcessTime, setData) => {
  const tmpData = []
  let lastDate
  let unparsedLastDate

  historial.forEach(function(h){
    if(h.type === 'start-process'){
      unparsedLastDate = h.date
      lastDate = parseDate(h.date)
      setStartProcessDate(lastDate)
      const today = new Date()

      if(setTotalProcessTime){
        const total = getDatesHoursDifference(h.date, today)
        setTotalProcessTime(total)
      }
    }
    else if(h.type === 'accept-step'){
      const thisDate = parseDate(h.date)
      const newData = {
        step: h.data,
        startDate: lastDate,
        endDate: thisDate,
        unparsedStartDate: lastDate,
        unparsedEndDate: h.date,
        totalStepTime: getDatesHoursDifference(unparsedLastDate, h.date),
        stepName: h.stepName? h.stepName : ''
      }
      tmpData.push(newData)
    }
  })
  setData(tmpData)
}
